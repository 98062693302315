import React, { useEffect, useRef } from 'react';

const Waveform = ({ stream }) => {
    const canvasRef = useRef(null);
    const analyser = useRef(null);
    const audioContext = useRef(null);

    useEffect(() => {
        if (stream) {
            audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContext.current.createMediaStreamSource(stream);
            analyser.current = audioContext.current.createAnalyser();
            analyser.current.fftSize = 2048; // Set the FFT size for better frequency resolution
            source.connect(analyser.current);

            const canvas = canvasRef.current;
            const parent = canvas.parentElement;
            canvas.width = parent.offsetWidth; // Set canvas width to parent width
            canvas.height = parent.offsetHeight; // Set canvas height to parent height
            const ctx = canvas.getContext('2d');
            const barWidth = 2; // Width of each bar in the waveform

            const draw = () => {
                const bufferLength = analyser.current.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);
                analyser.current.getByteTimeDomainData(dataArray); // Get time domain data

                ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas

                // Determine color based on the presence of wave
                const isWaveComing = dataArray.some(value => value > 128);
                const color = isWaveComing ? 'red' : 'blue';

                ctx.strokeStyle = color;
                ctx.beginPath();

                const sliceWidth = canvas.width * 1.0 / bufferLength;
                let x = 0;

                for (let i = 0; i < bufferLength; i++) {
                    const v = dataArray[i] / 128.0; // Normalize the data to 0-1
                    const y = v * canvas.height / 2;

                    if (i === 0) {
                        ctx.moveTo(x, y);
                    } else {
                        ctx.lineTo(x, y);
                    }

                    x += sliceWidth;
                }

                ctx.lineTo(canvas.width, canvas.height / 2);
                ctx.stroke();
            };

            // Draw the waveform
            draw();

            // Update the waveform periodically
            const intervalId = setInterval(draw, 100);
            return () => clearInterval(intervalId); // Cleanup on unmount
        }
    }, []);

    return <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />;
};

export default Waveform;