// import * as React from "react";
// import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
// import { Button, CircularProgress, Grid } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import Modal from "../Dds/modal";
// import useAdmin from "../../../hooks/Admin";

// export default function Campaign() {
//   const [open, setOpen] = React.useState(false);
//   const [status, setStatus] = React.useState(true);
//   const [edit, setEdit] = React.useState([]);
//   const { Dds, getDdsAction, Loading } = useAdmin();

//   React.useEffect(() => {
//     getDdsAction();
//   }, []);
//   const columns = [
//     { field: "id", headerName: "ID", flex: 1 },
//     { field: "trunk", headerName: "Campaign Name", flex: 2, minWidth: 130 },
//     { field: "number", headerName: "Start & End time", flex: 2, minWidth: 130 },
//     {
//       field: "countryId",
//       headerName: "Outbound Caller ID",
//       flex: 2,
//       minWidth: 130,
//     },
//     { field: "did_type", headerName: "Outbound Trunk", flex: 2, minWidth: 130 },
//     {
//       field: "createdAt",
//       headerName: "customer phone numbers",
//       flex: 2,
//       minWidth: 130,
//     },
//     {
//       field: "updatedAt",
//       headerName: "concurrency limit",
//       flex: 2,
//       minWidth: 130,
//     },
//     {
//       field: "campaign_count",
//       headerName: "retry count",
//       flex: 2,
//       minWidth: 130,
//     },
//     {
//       field: "campaign_gap",
//       headerName: "retry gap in mins",
//       flex: 2,
//       minWidth: 130,
//     },
//     {
//       field: "campaign_transfer",
//       headerName: "transfer code",
//       flex: 2,
//       minWidth: 130,
//     },
//     {
//       field: "campaign_destination",
//       headerName: "destination dialplan",
//       flex: 2,
//     },
//     { field: "campaign_dial", headerName: "dial timeout", flex: 2 },
//     {
//       field: "campaign_identifies",
//       headerName: "Each number unique identifies",
//       flex: 2,
//     },
//     {
//       field: "action",
//       headerName: "Action",
//       headerAlign: "center",
//       width: 230,
//       renderCell: (params) => (
//         <Grid
//           sx={{
//             display: "flex",
//             justifyContent: "space-around",
//             alignItem: "center",
//             width: "100%",
//           }}
//         >
//           <Button
//             variant="contained"
//             startIcon={<EditIcon />}
//             onClick={() => handleEdit(params.row)}
//           >
//             Edit
//           </Button>
//           <Button
//             variant="contained"
//             color="error"
//             startIcon={<DeleteIcon />}
//           >
//             Delete
//           </Button>
//         </Grid>
//       ),
//     },
//   ];
//   const handlopen = () => {
//     setOpen(true);
//     setStatus(true);
//   };
//   const handleEdit = (params) => {
//     setEdit(params);
//     setOpen(true);
//     setStatus(false);
//   };
//   return (
//     <>
//       <Box
//         sx={{
//           width: "100%",
//           display: Loading ? "block" : "none",
//           textAlign: "center",
//         }}
//       >
//         <CircularProgress />
//       </Box>
//       <Grid sx={{ my: 2, textAlign: "end" }}>
//         <Button variant="contained" startIcon={<AddIcon />} onClick={handlopen}>
//           Add
//         </Button>
//       </Grid>
//       {!Loading && (
//         <Box sx={{ height: "80vh", width: "100%" }}>
//           <DataGrid
//             rows={Dds}
//             columns={columns}
//             sx={{
//               ".css-1iyq7zh-MuiDataGrid-columnHeaders": {
//                 backgroundColor: "#d7d8d8", // Replace with your desired background color code or name
//                 color: "black", // Optional: Change text color for better contrast
//               },
//             }}
//             initialState={{
//               pagination: {
//                 paginationModel: {
//                   pageSize: 5,
//                 },
//               },
//             }}
//             pageSizeOptions={[5, 10, 15]}
//             disableRowSelectionOnClick
//           />
//         </Box>
//       )}
//       <Modal open={open} setOpen={setOpen} status={status} edit={edit} />
//     </>
//   );
// }

import React from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useStyles } from "./style";

const Campaign = () => {
  const classes = useStyles();
  const [callerId, setCallerId] = React.useState("");
  const [isValidMobileNumber, setIsValidMobileNumber] = React.useState(true);
  const [age, setAge] = React.useState("");

  const handleChangeSelect = (event) => {
    setAge(event.target.value);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    // Check if the entered value matches the pattern +91 followed by exactly 10 digits
    const mobileNumberPattern = /^\+91\d{10}$/;
    if (mobileNumberPattern.test(value)) {
      setIsValidMobileNumber(true);
    } else {
      setIsValidMobileNumber(false);
    }
    setCallerId(value);
  };

  const formatInput = (event) => {
    let { value } = event.target;
    // Remove any non-numeric characters
    value = value.replace(/\D/g, "");
    // Prepend "+91" if the value starts with digits
    if (/^\d/.test(value)) {
      value = "+91" + value;
    }
    // Limit the length to 13 characters (including "+91")
    value = value.slice(0, 13);
    setCallerId(value);
  };
  return (
    <>
      <Box
        sx={{
          border: "1px solid gray",
          height: "calc(100vh - 114px)",
          padding: "8px",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Campaign Name
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              id="outlined-basic"
              // label="Outlined"
              variant="outlined"
              fullWidth
              InputProps={{ classes: { root: classes.input } }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ pt: "12px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Concurrent Calls
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              id="outlined-basic"
              // label="Outlined"
              variant="outlined"
              fullWidth
              InputProps={{ classes: { root: classes.input } }}
              type="number"
            />
          </Grid>
        </Grid>
        <Grid container sx={{ pt: "12px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Campaign Caller ID
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              value={callerId}
              onChange={handleChange} // Handle input change
              onBlur={formatInput} // Format input onBlur
              error={!isValidMobileNumber} // Show error if not a valid mobile number
              helperText={
                !isValidMobileNumber &&
                "Enter a valid mobile number starting with +91 and exactly 10 digits"
              }
              InputProps={{ classes: { root: classes.input } }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ pt: "12px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Dial Prefix
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              id="outlined-basic"
              // label="Outlined"
              variant="outlined"
              fullWidth
              InputProps={{ classes: { root: classes.input } }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ pt: "12px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Email Reports to
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              id="outlined-basic"
              // label="Outlined"
              variant="outlined"
              fullWidth
              InputProps={{ classes: { root: classes.input } }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ pt: "12px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Email Reports
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              id="outlined-basic"
              // label="Outlined"
              variant="outlined"
              fullWidth
              InputProps={{ classes: { root: classes.input } }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ pt: "12px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Campaign Active
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              id="outlined-basic"
              // label="Outlined"
              variant="outlined"
              fullWidth
              InputProps={{ classes: { root: classes.input } }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ pt: "12px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Call Groups
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
          <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                // label="Age"
                onChange={handleChangeSelect} 
                classes={{ root: classes.input }}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container sx={{ pt: "12px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Schedule
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <TextField
              id="outlined-basic"
              // label="Outlined"
              variant="outlined"
              fullWidth
              InputProps={{ classes: { root: classes.input } }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ pt: "12px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Destination - Live Person Answered
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
          <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                // label="Age"
                classes={{ root: classes.input }}
                onChange={handleChangeSelect}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container sx={{ pt: "12px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Destination - Asnwering Machine
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                // label="Age"
                classes={{ root: classes.input }}
                onChange={handleChangeSelect}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Campaign;
