import React, { useState } from 'react';
// import { Cog as CogIcon } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { useDeepgram, voiceMap, voices } from '../../../context/Deepgram';
import { useToast } from '../../../context/Toast';
import SettingsIcon from '@mui/icons-material/Settings';
const arrayOfVoices = Object.entries(voices).map((e) => ({
  ...e[1],
  model: e[0],
}));

const ModelSelection = ({ model, setModel }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="voice-label">Selected voice</InputLabel>
      <Select
        labelId="voice-label"
        value={model}
        onChange={(e) => setModel(e.target.value)}
        label="Selected voice"
      >
        {arrayOfVoices.map((voice) => (
          <MenuItem key={voice.model} value={voice.model}>
            <Avatar alt={voice.name} src={voice.avatar} />
            <Typography variant="body2">{voice.name}</Typography>
            <Typography variant="caption">
              {voice.model} - {voice.language} {voice.accent}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const Settings = () => {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const { ttsOptions, setTtsOptions } = useDeepgram();
  const [model, setModel] = useState(ttsOptions?.model);
  const theme = useTheme();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const saveAndClose = () => {
    setTtsOptions({ ...ttsOptions, model });
    toast('Options saved.');
    handleClose();
  };

  return (
    <>
      <div className="flex items-center gap-2.5 text-sm">
        <span className="bg-gradient-to-r to-[#13EF93]/50 from-[#149AFB]/80 rounded-full flex">
          <a
className={`relative m-px bg-black w-[9.25rem] md:w-10 h-10 rounded-full text-sm p-2.5 group hover:w-[9.25rem] transition-all ease-in-out duration-1000 overflow-hidden whitespace-nowrap`}            href="#"
            onClick={handleOpen}
          >
            <SettingsIcon className="w-5 h-5 transition-transform ease-in-out duration-2000 group-hover:rotate-180" />
            <span className="ml-2.5 text-xs">Change settings</span>
          </a>
        </span>
        <span className="hidden md:inline-block text-white/50 font-inter">
          Voice: <span className="text-white">{voiceMap(ttsOptions?.model)?.name}</span>
        </span>
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Text-to-Speech Settings</Typography>
          <ModelSelection model={model} setModel={setModel} />
        </DialogContent>
        <div className="flex justify-end p-4">
          <Button variant="contained" color="primary" onClick={saveAndClose}>
            Save
          </Button>
        </div>
      </Dialog>
    </>
  );
};