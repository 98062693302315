import React from "react";
import { Button, Chip, Divider, Grid } from "@mui/material";
import { Chiplable } from "../../../utils/HomescreenCard";
import styles from "./index.module.scss";

const Usecase = () => {
  return (
    <section className={styles.main_usecase_container}>
      <Grid className={styles.sub_usecase_container}>
        <Grid className={styles.btn_main_container}>
          <Button className={styles.btn_container}>UseCases</Button>
        </Grid>
        <div data-aos="fade-up" data-aos-duration="3000">
          <h1>Solutions for everything.</h1>
          <Grid>
            <p>
              Customer support, front desk, outbound sales, lead generation,
              telehealth, food ordering, transportation logistics, employee
              training, roleplay, or whatever else you want to build.
            </p>
          </Grid>
        </div>
        <div className={styles.chip_main_container}>
          <Grid container spacing={2}>
            {
              Chiplable?.map((item, index) => {
                return (
                  <>
                    <Grid key={index} item xs={12} md={4} lg={4} xl={3}>
                      <Chip label={item.label} style={{ background: "aqua", width: "100%" }} />
                    </Grid>
                  </>
                )
              })
            }
          </Grid>
        </div>

        <Grid className={styles.divider_container}>
          <Divider />
        </Grid>
      </Grid>
    </section>
  );
};

export default Usecase;
