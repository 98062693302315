import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  conversation_main_container: {
    // height: "calc(100vh - 278px)",
    position: "relative",
    width: "100%",
    border: "1px solid #E2E8F0",
    borderRadius: "10px",

    // padding: "10px",
  },

  conversation_title: {
    padding: "10px",
  },
  conversation_container: {
    height: "calc(100vh - 384px)",
  },
  transcript_conatiner: {
    padding: "6px 10px",
  },
  text_container: {
    position: "absolute",
    bottom: "16px",
    padding: "0 10px",
    zIndex: 2,
    background: "white",
    // width: "100%",
  },
  textField: {
    "&.MuiInputBase-input": {
      "&. MuiOutlinedInput-input": {
        padding: 0,
      },
    },
  },
}));
