import React from "react";
import Header from "../../components/Header";
import FAQPage from "../../components/faq";
import Footer from "../../components/footer";

export default function index() {
  return (
    <>
      <Header />
      <FAQPage />
      <Footer />
    </>
  );
}
