import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CallIcon from "@mui/icons-material/Call";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PublicIcon from "@mui/icons-material/Public";
import { Outlet, Link } from "react-router-dom";
import styles from "./index.module.scss";
import { Avatar, Grid, ListItemText } from "@mui/material";
import logo from "../assets/images/logo/quicksmart_480.png";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import UseAuth from "../hooks/Auth";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CampaignIcon from '@mui/icons-material/Campaign';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
// import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  // const navigate = useNavigate();
  // const currentPath = history.location.pathname;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { LogoutAction } = UseAuth();


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlelogout = () => {
    LogoutAction();
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* <Grid sx={{ display: "flex", justifyContent: "space-between" }}> */}
          <Grid sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h6" noWrap component="div">
              Admin
            </Typography>
          </Grid>
          <Grid>
            <Avatar alt="Remy Sharp" src={logo} />
          </Grid>
          {/* </Grid> */}
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box
          sx={{
            height: "calc(100vh - 64px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <List>
            <div className={styles.dash_table}>
              <ListItemButton>
                <Link to="/admin/call">
                  <ListItemIcon>
                    <CallIcon />
                  </ListItemIcon>
                  <ListItemText>

                    Calls
                  </ListItemText>
                </Link>
              </ListItemButton>
              <ListItemButton>
                <Link to="/admin/cases">
                  <ListItemIcon>
                    <SettingsAccessibilityIcon />
                  </ListItemIcon>
                  <ListItemText>
                    Use Cases
                  </ListItemText>
                </Link>
              </ListItemButton>
              <ListItemButton>
                <Link to="/admin/country">
                  <ListItemIcon>
                    <PublicIcon />
                  </ListItemIcon>
                  <ListItemText>
                    Country
                  </ListItemText>
                </Link>
              </ListItemButton>
              <ListItemButton>
                <Link to="/admin/dds">
                  <ListItemIcon>
                    <ContactPhoneIcon />
                  </ListItemIcon>
                  <ListItemText>
                    DID'S
                  </ListItemText>
                </Link>
              </ListItemButton>
              <ListItemButton>
                <Link to="/admin/customer">
                  <ListItemIcon>
                    <SupportAgentIcon />
                  </ListItemIcon>
                  <ListItemText>

                    Customer
                  </ListItemText>
                </Link>
              </ListItemButton>
              {/* <ListItemButton>
                <Link to="/admin/campaign">
                  <ListItemIcon>
                    <CampaignIcon />
                  </ListItemIcon>
                  <ListItemText>
                    Campaign
                  </ListItemText>
                </Link>
              </ListItemButton>
              <ListItemButton>
                <Link to="/admin/callaction">
                  <ListItemIcon>
                    <SettingsPhoneIcon />
                  </ListItemIcon>
                  <ListItemText>
                    CallAction
                  </ListItemText>
                </Link>
              </ListItemButton> */}
            </div>
          </List>
          <Box sx={{ mt: "auto" }}>
            <List>
              <div className={styles.dash_table}>
                <ListItemButton onClick={handlelogout}>
                  <Link>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText>
                      Logout
                    </ListItemText>
                  </Link>
                </ListItemButton>
              </div>
            </List>
          </Box>
        </Box>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>

    </Box>
  );
}
