import React from "react";
import { Grid, TextField } from "@mui/material";
import { useStyles } from "./style";

const GeneralSettings = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          Concurrent Calls
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <TextField
            id="outlined-basic"
            // label="Outlined"
            variant="outlined"
            fullWidth
            InputProps={{ classes: { root: classes.input } }}
            type="number"
          />
        </Grid>
      </Grid>
      <Grid container sx={{ pt: "12px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          Maximum Retries
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <TextField
            id="outlined-basic"
            // label="Outlined"
            variant="outlined"
            fullWidth
            InputProps={{ classes: { root: classes.input } }}
            type="number"
          />
        </Grid>
      </Grid>
      <Grid container sx={{ pt: "12px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          Retry time
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <TextField
            id="outlined-basic"
            // label="Outlined"
            variant="outlined"
            fullWidth
            InputProps={{ classes: { root: classes.input } }}
            type="number"
          />
        </Grid>
      </Grid>
      <Grid container sx={{ pt: "12px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          Wait time
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <TextField
            id="outlined-basic"
            // label="Outlined"
            variant="outlined"
            fullWidth
            InputProps={{ classes: { root: classes.input } }}
            type="number"
          />
        </Grid>
      </Grid>
      <Grid container sx={{ pt: "12px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          Report From Address
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <TextField
            id="outlined-basic"
            // label="Outlined"
            variant="outlined"
            fullWidth
            InputProps={{ classes: { root: classes.input } }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GeneralSettings;
