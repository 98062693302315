import React from "react";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import useAdmin from "../../hooks/Admin";
import { useStyles } from "./styles";
import EmptyPlaceholder from "../../common/EmptyPlaceholder";
import About from "../../components/about";
import Footer from "../../components/footer";
const Chat = () => {
  const { id } = useParams();
  const { Chat, getChatAction } = useAdmin();
  const classes = useStyles();
  React.useEffect(() => {
    // getChatAction("b8bac416-6677-4735-ad02-a86d2edd878a");
    getChatAction(id);
  }, [id]);

  return (
    <Box p={2}>
      <Box
        sx={{
          height: "83vh",
          overflowY: "scroll",
          // marginBottom: "15px",
        }}
        className={classes.transcript_conatiner}
      >
        {Chat?.length > 0 ? (
          Chat?.map((message, index) => {
            const currentTime = new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
            return (
              <>
                <Box
                  display="flex"
                  flexDirection={
                    message.role === "user" ? "row-reverse" : "row"
                  }
                  // justifyContent={message.role === "user" ? "end" : "start"}
                  // alignItems={message.role === "user" ? "end" : "start"}
                  textAlign={message.role === "user" ? "end" : "start"}
                  sx={{ px: 1, py: 1 }}
                >
                  <Avatar src={message.avatar} alt={message?.role} />

                  <Box mx={2} maxWidth={"70%"}>
                    <Typography variant="subtitle1" textTransform="capitalize">
                      {message.role}
                    </Typography>

                    <Card
                      sx={{
                        //   display: "inline-block",
                        //   float: "right",
                        bgcolor: "grey.0",
                        boxShadow: "none",
                        ml: 1,
                      }}
                    >
                      <CardContent
                        sx={{
                          p: 1,
                          pb: "8px !important",
                          width: "fit-content",
                          mx: "auto",
                          border: `1px solid`,
                          bgcolor: "grey.100",
                          borderColor: "grey.200",
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                overflowWrap: "anywhere",
                                display: "inline-block",
                              }}
                            >
                              {message?.content}
                              {/* <WordList wrapper={wrapper} currentTime={currentTime} words={history.comment_text} /> */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>

                    <Typography variant="caption" color="textSecondary">
                      {currentTime}
                    </Typography>
                  </Box>
                </Box>
              </>
            );
          })
        ) : (
          <Grid className={classes.transcript_conatiner}>
            <EmptyPlaceholder msg="No data" height={468} />
          </Grid>
        )}
      </Box>

      <About />
      <Footer />
    </Box>
  );
};

export default Chat;
