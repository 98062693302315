import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Button, CircularProgress, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "../Country/modal";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
//  import { getCountry, AddCountry } from "../../../api/Admin";
import useAdmin from "../../../hooks/Admin";

const columns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "country", headerName: "Country", flex: 3 },
  { field: "countryCode", headerName: "Country Code", flex: 2 },
  { field: "visibility", headerName: "Visibility", flex: 2 },
  { field: "createdAt", headerName: "Created At", flex: 2 },
  { field: "updatedAt", headerName: "Updated At", flex: 2 },
  // {
  //   field: "Edit",
  //   headerName: "Edit",
  //   width: 150,
  //   renderCell: (params) => (
  //     <Button variant="contained" startIcon={<EditIcon />}>
  //       Edit
  //     </Button>
  //   ),
  // },
  // {
  //   field: "Delete",
  //   headerName: "Delete",
  //   width: 150,
  //   renderCell: (params) => (
  //     <Button variant="contained" color="success" startIcon={<DeleteIcon />}>
  //       Delete
  //     </Button>
  //   ),
  // },
  // Add more columns as needed
];

export default function DataGridDemo() {
  const [open, setOpen] = React.useState(false);
  const { country, countryAction, Loading } = useAdmin();

  React.useEffect(() => {
    countryAction();
  }, []);

  const handlopen = () => {
    setOpen(true);
  };
  return (
    <>

      <Box
        sx={{
          width: "100%",

          display: Loading ? "block" : "none",
          textAlign: "center",
        }}
      >
        <CircularProgress />
      </Box>


      <Grid sx={{ my: 2, textAlign: "end" }}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handlopen}>
          Add
        </Button>
      </Grid>

      {!Loading && (
        <Box sx={{ height: "80vh", width: "100%" }}>
          <DataGrid
            rows={country}
            columns={columns}
            sx={{
              ".css-1iyq7zh-MuiDataGrid-columnHeaders": {
                backgroundColor: "#d7d8d8", // Replace with your desired background color code or name
                color: "black", // Optional: Change text color for better contrast
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 15]}
            disableRowSelectionOnClick
          />
        </Box>
      )}

      <Modal open={open} setOpen={setOpen} />
    </>
  );
}
