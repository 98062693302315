import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  main_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100vw",
    height: "100vh",
    // background: 'radial-gradient(circle, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.8) 30%, rgba(1,1,1,1) 100%)',
    // background: 'linear-gradient(to right, #000020, #200040)',
    backgroundImage: 'linear-gradient(180deg, #434343 0%, #414345 10%,  black 100%)',
    // backgroundSize: '600% 600%',
    // animation: 'Gradient 20s ease infinite'
  },
  section_2: {
    height: 'calc(100vh - 100px)',
    marginRight: '12px',
    marginLeft: '12px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // background: 'rgba(1, 1, 1, 0.1)', /* Semi-transparent white */
    // border: '1px solid rgba(255, 255, 255, 0.2)', /* Light border */
    // borderRadius: '10px', /* Rounded corners */
    // padding: '20px', /* Padding inside the card */
    // backdropFilter: 'blur(10px)', /* Apply blur effect */
    // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' /* Optional: Add a subtle shadow for depth */
  },
  section_2_title: {
    fontWeight: '700',
    // paddingBottom: '2rem',
    color: "rgba(255, 255, 255, 0.9)",
  },
  section_2_btn: {

    '&.MuiButton-root': {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "15px 50px",
      borderRadius: "30px",
      color: "rgba(0, 0, 0, 1)",
      background: "rgba(255, 255, 255, 1)",
      fontWeight: "700",
      lineHeight: "24px",
      // marginBottom: '1.25rem',
      '&:hover': {
        background: "rgba(255, 255, 255, 0.8)", // Example hover effect: change background opacity
        // Add other hover styles here
      },
    },

  },
  section_2_caption: {
    display: "flex",
    // maxWidth: "200",
    textAlign: "center"

  },
  conversation_title: {
    padding: "10px",
  },
  conversation_container: {
    height: "calc(100vh - 384px)",
  },
  transcript_conatiner: {
    padding: "6px 10px",
  },
  text_container: {
    position: "absolute",
    bottom: "16px",
    padding: "0 10px",
    zIndex: 2,
    background: "white",
    // width: "100%",
  },
  textField: {
    "&.MuiInputBase-input": {
      "&. MuiOutlinedInput-input": {
        padding: 0,
      },
    },
  },
}));
