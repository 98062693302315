import React from "react";
import { Button, Divider, Grid } from "@mui/material";
import PricingImage from "../../../assets/images/Homepage/pricing.webp";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "./index.module.scss";

const Pricing = () => {
  return (
    <section className={styles.pricing_main_component}>
      <Grid className={styles.pricing_sub_component}>
        <Grid className={styles.pricing_img_container}>
          <img src={PricingImage} alt="PricingImage" />
        </Grid>
        <Grid className={styles.data_component}>
          <Grid className={styles.btn_main_container}>
            <Button className={styles.btn_container}>Pricing</Button>
          </Grid>
          <Grid container className={styles.title_main_container}>
            <Grid className={styles.title1}>QuickSmart costs &nbsp;</Grid>
            <Grid className={styles.title2}>$0.03 per minute</Grid>
          </Grid>
          {/* <h2 style={{ color: "white" }}>QuickSmart costs $0.025 per minute</h2> */}
          <Grid className={styles.sub_text_main}>
            <Grid className={styles.extra}></Grid>
            <Grid className={styles.text_main}>
              Bring your own keys for the underlying providers like ElevenLabs and
              OpenAI, or we can pass through the costs to you.
            </Grid>
          </Grid>
          <Grid className={styles.data_info}>
            <CheckCircleOutlineIcon
              className={styles.icon}
              style={{ color: "rgb(148, 255, 210)" }}
            />
            <Grid className={styles.text} sx={{ color: "white" }}>
              No hidden costs
            </Grid>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ color: "white", border: "1px solid white", height: "22px" }}
            />
            <CheckCircleOutlineIcon
              className={styles.icon}
              style={{ color: "rgb(148, 255, 210)" }}
            />
            <Grid className={styles.text} sx={{ color: "white" }}>
              Bring your own API keys
            </Grid>
          </Grid>
          <Grid className={styles.contact_main_container}>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIosIcon />}
              sx={{
                zIndex: 10,
                position: "relative",
                borderRadius: "18px",
                backgroundColor: "rgb(153 246 228 / 1)",
                color: "black",
                "&:hover": {
                  color: "white", // Change text color to white on hover
                },
              }}
              href="https://calendly.com/callanywhere/30min"
            >
              Contacts Sales
            </Button>
            {/* <Button
              variant="contained"
              endIcon={<ArrowForwardIosIcon />}
              sx={{
                zIndex: 10,
                position: "relative",
                borderRadius: "18px",
                background: "transparent",
                border: "solid 1px",
                borderColor: "white",
              }}
            >
              Get $10 free credit
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};

export default Pricing;
