"use client";

import {
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";


const MessageMetadataContext = createContext({});

const MessageMetadataContextProvider = ({
  children,
}) => {
  const [messageData, setMessageData] = useState([]);

  const addMessageData = useCallback((queueItem) => {
    setMessageData((q) => [...q, queueItem]);
  }, []);

  return (
    <MessageMetadataContext.Provider
      value={{
        messageData,
        setMessageData,
        addMessageData,
      }}
    >
      {children}
    </MessageMetadataContext.Provider>
  );
};

function useMessageData() {
  return useContext(MessageMetadataContext);
}

export { MessageMetadataContextProvider, useMessageData };
