import { createContext, useCallback, useContext, useState } from "react";

const AudioStoreContext = createContext({});

export const AudioStoreContextProvider = ({ children }) => {
 const [audioStore, setAudioStore] = useState([]);

 const addAudio = useCallback((queueItem) => {
    setAudioStore((q) => [...q, queueItem]);
 }, []);

 return (
    <AudioStoreContext.Provider
      value={{
        audioStore,
        addAudio,
      }}
    >
      {children}
    </AudioStoreContext.Provider>
 );
};

export function useAudioStore() {
 return useContext(AudioStoreContext);
}