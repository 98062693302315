// LoginForm.js
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Typography, Container, Grid } from "@mui/material";
import { login } from "../../api/auth";
import UseAuth from "../../hooks/Auth";
import { useNavigate } from "react-router-dom";
const LoginForm = () => {
  const initialValues = {
    email: "",
    password: "",
    // name: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
    // name: Yup.string().required("Required"),
  });
  const { loginAction, isAuth } = UseAuth();
  // const onSubmit = async (values) => {
  //   try {
  //     const response = await login(values);

  //     const data = response.data;
  //     console.log("Form logvalues:", data.tokens.access.token);
  //   } catch (error) {
  //     console.error("Error during form submission:", error);
  //   }
  // };
  const navigate = useNavigate();
  const onSubmit = (values) => {
    loginAction(values);
  };
  React.useEffect(() => {
    if (isAuth === true) {
      // history.push(`/admin/call`);
      navigate("/admin/call");
    }
  }, [isAuth]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" sx={{ mb: 2 }}>
              Login
            </Typography>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="password"
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid> */}
        </Grid>
        <Button
          type="submit"
          sx={{ mt: 2 }}
          fullWidth
          variant="contained"
          color="primary"
        >
          Login
        </Button>
      </form>
    </Container>
  );
};

export default LoginForm;
