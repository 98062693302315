import client from "../client";

export const getCall = () => {
  return client.get(`/v1/call`);
};

export const getCases = () => {
  return client.get(`/v1/usecase`);
};
export const getCountry = () => {
  return client.get(`/v1/country`);
};
export const AddCountry = (params) => {
  return client.post(`/v1/country`, params);
};

export const AddCases = (params) => {
  return client.post(`/v1/usecase`, params);
};
export const getDds = () => {
  return client.get(`/v1/dids`);
};
export const AddDds = (params) => {
  return client.post(`/v1/dids`, params);
};

export const getChat = (id) => {
  return client.get(`/v1/call/conversation/${id}`);
};
export const getPostCallAnalysis = (id) => {
  return client.get(`/v1/postCall/${id}`);
};

export const editcases = (params) => {
  let { id, ...data } = params;
  return client.patch(`/v1/usecase/${id}`, data);
};

export const editdids = (params) => {
  let { id, ...data } = params;
  return client.patch(`/v1/dids/${id}`, data);
};

export const getCustomer = () => {
  return client.get(`/v1/customer`);
};