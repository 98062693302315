"use client";

import { useQueue } from "@uidotdev/usehooks";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";



const MicrophoneContext = createContext({});

const MicrophoneContextProvider = ({
  children,
}) => {
  const [microphone, setMicrophone] = useState();
  const [stream, setStream] = useState();
  const [microphoneOpen, setMicrophoneOpen] = useState(false);

  const {
    add: enqueueBlob, // addMicrophoneBlob,
    remove: removeBlob, // removeMicrophoneBlob,
    first: firstBlob, // firstMicrophoneBlob,
    size: queueSize, // countBlobs,
    queue, // : microphoneBlobs,
  } = useQueue([]);

  useEffect(() => {
    async function setupMicrophone() {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          noiseSuppression: true,
          echoCancellation: true,
        },
      });

      setStream(stream);

      const microphone = new MediaRecorder(stream);

      setMicrophone(microphone);
    }

    if (!microphone) {
      setupMicrophone();
    }
  }, [enqueueBlob, microphone, microphoneOpen]);

  useEffect(() => {
    if (!microphone) return;

    microphone.ondataavailable = (e) => {
      if (microphoneOpen) enqueueBlob(e.data);
    };

    return () => {
      microphone.ondataavailable = null;
    };
  }, [enqueueBlob, microphone, microphoneOpen]);

  const stopMicrophone = useCallback(() => {
    if (microphone?.state === "recording") microphone?.pause();

    setMicrophoneOpen(false);
  }, [microphone]);

  const startMicrophone = useCallback(() => {
    if (microphone?.state === "paused") {
      microphone?.resume();
    } else {
      microphone?.start(250);
    }

    setMicrophoneOpen(true);
  }, [microphone]);

  useEffect(() => {
    const eventer = () =>
      document.visibilityState !== "visible" && stopMicrophone();

    window.addEventListener("visibilitychange", eventer);

    return () => {
      window.removeEventListener("visibilitychange", eventer);
    };
  }, [stopMicrophone]);

  return (
    <MicrophoneContext.Provider
      value={{
        microphone,
        setMicrophone,
        startMicrophone,
        stopMicrophone,
        microphoneOpen,
        enqueueBlob,
        removeBlob,
        firstBlob,
        queueSize,
        queue,
        stream,
      }}
    >
      {children}
    </MicrophoneContext.Provider>
  );
};

function useMicrophone() {
  return useContext(MicrophoneContext);
}

export { MicrophoneContextProvider, useMicrophone };
