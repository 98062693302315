import React from "react";
import { makeStyles } from "@mui/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Link,
  Grid,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = (theme) => ({
  section: {
    marginBottom: theme.spacing(1),
  },
  link: {
    margin: theme.spacing(0, 1),
  },
});

const Footer = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <footer
      className={classes.footer}
      style={{ background: "#313131", color: "white" }}
    >
      <Container maxWidth="lg" sx={{ mt: 6, color: "white" }}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6">About Us</Typography>
            <Typography variant="body2">
              We are a passionate team dedicated to providing quality services
              and products.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6">Our Mission</Typography>
            <Typography variant="body2">
              To enhance the lives of our customers through innovative solutions
              and exceptional experiences.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6">Latest News</Typography>
            <Typography variant="body2">
              Stay updated with our latest announcements and developments.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6">Contact Us</Typography>
            <Typography variant="body2">
              Feel free to reach out to us at
              <a href="mailto:info@quicksmart.ai"> info@quicksmart.ai</a>.
            </Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            mt: 8,
            pb: 4,
          }}
        >
          <Typography variant="body2" align="center">
            © {new Date().getFullYear()} QuickSmart. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
