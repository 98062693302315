import {
  Avatar,
  Box,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AgentModel from "../../components/AgentModel";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function Index() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "start",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item sx={{ mb: 2, px: 5, pt: 5, pb: 3, height: "88vh" }}>
            <Box>
              <Typography variant="h3" gutterBottom>
                Webhook Settings
              </Typography>
              <Typography variant="subtitle1">
                Configure your webhook URL to receive real-time event
                notifications.
              </Typography>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                  {/* <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Payment Method" {...a11yProps(0)} />
                    <Tab label="Billing History" {...a11yProps(1)} />
                  </Tabs> */}
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12} sx={8} md={8} lg={6} xl={4}>
                      <Item>
                        <Typography variant="h6" gutterBottom>
                          Webhook URL
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          fontWeight={400}
                          gutterBottom
                        >
                          Check our.{" "}
                          <Link
                            href="link-to-your-wiki"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            documentation
                          </Link>
                          for detail
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          label="Outlined"
                          fullWidth
                          variant="outlined"
                        />
                        <Box
                          sx={{
                            mt: 3,
                            textAlign: "end",
                          }}
                        >
                          <Button variant="contained" disabled>
                            add
                          </Button>
                        </Box>
                      </Item>
                    </Grid>
                  </Grid>
                </Box>

                {/* <CustomTabPanel value={value} index={0}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    sx={{ justifyContent: "start" }}
                  >
                    Add Payment Method
                  </Button>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Table /> */}
                {/* </CustomTabPanel> */}
              </Box>
            </Box>
          </Item>
        </Grid>
      </Grid>
      <AgentModel open={open} setOpen={setOpen} />
    </>
  );
}

export default Index;
