
import { DgSvg } from "./DgSvg";
import { useMessageData } from "../../../context/MessageMetadata";
import { useAudioStore } from "../../../context/AudioStore";
import { voiceMap } from "../../../context/Deepgram";
import { Avatar } from "@mui/material";

export const AgentAvatar = ({
  message,
  className = "",
}) => {
  const { audioStore } = useAudioStore();
  const { messageData } = useMessageData();

  const foundAudio = audioStore.findLast((item) => item.id === message.id);
  const foundData = messageData.findLast((item) => item.id === message.id);

  if (foundAudio?.model) {
    return <Avatar src={voiceMap(foundAudio?.model).avatar} />;
  }

  if (foundData?.ttsModel) {
    return <Avatar src={voiceMap(foundData?.ttsModel).avatar} />;
  }

  return <DgSvg />;
};
