import React from "react";
import { Button, Grid, IconButton } from "@mui/material";
import cardbackground from "../../../assets/images/Homepage/cardbackground.webp";
import TopImg from "../../../assets/images/Homepage/cardshadowtop.svg";
import BottomImg from "../../../assets/images/Homepage/cardshadowbottom.svg";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import styles from "./index.module.scss";

const Aimade = () => {
  return (
    <section className={styles.main_aimade_container}>
      <Grid className={styles.child_aimade_container}>
        <Grid className={styles.btn_main_container}>
          <Button className={styles.btn_container}>Ai made for everyone</Button>
        </Grid>
        <Grid>
          <h1>Benchmarking AI Voice Bots: How We Excel</h1>
        </Grid>
        <Grid container className={styles.card_main_component}>
          <Grid className={styles.img_container}>
            <img src={cardbackground} alt="cardbackground" />
          </Grid>
          <Grid item sm={12} md={6} lg={6} className={styles.aimade_first_card}>
            <Grid className={styles.topimage}>
              <img src={TopImg} alt="TopImage" />
            </Grid>
            <Grid className={styles.bottomimage}>
              <img src={BottomImg} alt="BottomImage" />
            </Grid>
            <Grid className={styles.first_card_main}>
              <Grid>
                <IconButton>
                  <AddRoadIcon sx={{ color: "#BCC5CC" }} />
                </IconButton>
              </Grid>
              <h3>QuickSmart</h3>
              <p>
                Appoiment booking, Voice mail, IVR detection, SMS, E-mail,
                WhatsApp messages, many more...
              </p>
              <Grid container className={styles.text_component}>
                <Grid item sm={12} md={4} lg={4}>
                  <Grid
                    sx={{
                      fontSize: "14px",
                      pb: "8px",
                      color: "rgb(188 197 204 / 1)",
                    }}
                  >
                    | Cost
                  </Grid>
                  <Grid className={styles.card_text}>$0.03 per min </Grid>
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <Grid
                    sx={{
                      fontSize: "14px",
                      pb: "8px",
                      color: "rgb(188 197 204 / 1)",
                    }}
                  >
                    | Response time
                  </Grid>
                  <Grid className={styles.card_text}>800 ms</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6} lg={6} className={styles.second_card_main}>
            {/* <Grid className={styles.topimage}>
              <img src={TopImg} alt="TopImage" />
            </Grid>
            <Grid className={styles.bottomimage}>
              <img src={BottomImg} alt="BottomImage" />
            </Grid> */}
            <Grid className={styles.second_card_sub_conatiner}>
              <Grid className={styles.second_card}>
                <Grid>
                  <IconButton>
                    <AddRoadIcon sx={{ color: "#BCC5CC" }} />
                  </IconButton>
                </Grid>
                <h3>Others</h3>
                <p>Limited action provide</p>
                <Grid container className={styles.text_component}>
                  <Grid item sm={12} md={4} lg={4}>
                    <Grid sx={{ fontSize: "14px", pb: "8px" }}>| Cost</Grid>
                    <Grid className={styles.card_text}>$0.05 per min </Grid>
                  </Grid>
                  <Grid item sm={12} md={4} lg={4}>
                    <Grid sx={{ fontSize: "14px", pb: "8px" }}>
                      | Response time{" "}
                    </Grid>
                    <Grid className={styles.card_text}>~ 2000 ms</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};

export default Aimade;
