import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Allcountry,
  Addcountry,
  Allcases,
  Addcases,
  Alldds,
  AddDds,
  Allcall,
  getChat,
  editCases,
  editdids,
  getCustomer,
  getPostCallAnalysis,
} from "../../store/Admin";
function useAdmin() {
  const dispatch = useDispatch();
  const country = useSelector((state) => state.admin.country);
  const Loading = useSelector((state) => state.admin.ui.login.loading);
  const cases = useSelector((state) => state.admin.cases);
  const Dds = useSelector((state) => state.admin.Dds);
  const Call = useSelector((state) => state.admin.call);
  const Toaststatus = useSelector((state) => state.admin.status);
  const Toaststatus2 = useSelector((state) => state.admin.toast);
  const Chat = useSelector((state) => state.admin.chat);
  const postCallAnalysis = useSelector((state) => state.admin.postCallData);
  const Customer = useSelector((state) => state.admin.customer);

  const callAction = (params) => {
    dispatch(Allcall(params));
  };
  const countryAction = (params) => {
    dispatch(Allcountry(params));
  };
  const AddcountryAction = (params) => {
    dispatch(Addcountry(params));
  };
  const casesAction = () => {
    dispatch(Allcases());
  };
  const AddcasesAction = (params) => {
    dispatch(Addcases(params));
  };
  const getDdsAction = (params) => {
    dispatch(Alldds(params));
  };
  const addDdsAction = (params) => {
    dispatch(AddDds(params));
  };
  const getChatAction = (params) => {
    dispatch(getChat(params));
  };
  const getPostCallAnalysisAction = (params) => {
    dispatch(getPostCallAnalysis(params));
  };
  const editCasesAction = (params) => {
    dispatch(editCases(params));
  };
  const editDidsAction = (params) => {
    dispatch(editdids(params));
  };
  const getCustomerAction = (params) => {
    dispatch(getCustomer(params));
  };
  return {
    country,
    cases,
    Dds,
    Loading,
    Call,
    Toaststatus,
    Toaststatus2,
    Chat,
    postCallAnalysis,
    Customer,
    countryAction,
    AddcountryAction,
    casesAction,
    AddcasesAction,
    getDdsAction,
    addDdsAction,
    callAction,
    getChatAction,
    editCasesAction,
    editDidsAction,
    getCustomerAction,
    getPostCallAnalysisAction,
  };
}

export default useAdmin;
