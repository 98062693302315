import * as React from "react";
import { useStyles } from "./styles";
import { Container, Stack, Typography } from "@mui/material";
import Header from "../../common/Demo/Header";
import Conversation from "./Conversation";
import { ToastContextProvider } from "../../context/Toast";
import { MicrophoneContextProvider } from "../../context/Microphone";
import { AudioStoreContextProvider } from "../../context/AudioStore";
import { NowPlayingContextProvider } from "react-nowplaying";
import { MessageMetadataContextProvider } from "../../context/MessageMetadata";
import { DeepgramContextProvider } from "../../context/Deepgram";

export default function Demo() {
  const classes = useStyles()
  return (
    <ToastContextProvider >
      <MicrophoneContextProvider>
        <AudioStoreContextProvider>
          <NowPlayingContextProvider>
            <MessageMetadataContextProvider>
              <DeepgramContextProvider>
                <Stack className={classes.main_container}>

                  <Stack>
                    <Header />
                  </Stack>
                  <Container>
                    <Stack className={classes.section_2}>
                      <Conversation />
                    </Stack>
                  </Container>
                  <Stack>
                    <Typography variant="caption" color="#ffffff">
                      © 2024 QuickSmart AI, Inc.
                    </Typography>
                  </Stack>
                </Stack>
              </DeepgramContextProvider>
            </MessageMetadataContextProvider>
          </NowPlayingContextProvider>
        </AudioStoreContextProvider>
      </MicrophoneContextProvider>
    </ToastContextProvider>
  );
}
