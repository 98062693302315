import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Button, CircularProgress, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "../call/modal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getCall } from "../../../api/Admin";
import useAdmin from "../../../hooks/Admin";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
export default function DataGridDemo() {
  const [open, setOpen] = React.useState(false);
  const { Loading, Call, callAction } = useAdmin();
  const parsedData = React.useMemo(
    () =>
      Call.map((item) => ({
        ...item,
        data: JSON.parse(item.data), // Parse the 'x' property if it's stringified JSON
      })).reverse(),
    [Call]
  );
  const navigate = useNavigate();

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "cid",
      headerName: "CID",
      flex: 2,
      valueGetter: (params) => params.row.data.accountcode,
    },

    {
      field: "cause-txt",
      headerName: "Cause",
      flex: 2,
      valueGetter: (params) => params.row.data["cause-txt"],
    },
    // { field: "campaign_id", headerName: "Campaign ID", flex: 2 },
    // { field: "schema_name", headerName: "Schema Name", flex: 2 },
    { field: "extension", headerName: "Extension", flex: 2 },
    { field: "outboundCallerId", headerName: "Outbound Caller ID", flex: 2 },
    { field: "source", headerName: "Source", flex: 2 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            variant="contained"
            onClick={() => handleclick(params.row.uniqueId)}
          >
            <ChatIcon />
          </IconButton>
          <IconButton>
            <AutoGraphIcon
              onClick={() => handleclick(`post/` + params.row.id)}
            />
          </IconButton>
        </>
      ),
    },
    // {
    //   field: "Delete",
    //   headerName: "Delete",
    //   width: 150,
    //   renderCell: (params) => (
    //     <Button variant="contained" color="success" startIcon={<DeleteIcon />}>
    //       Delete
    //     </Button>
    //   ),
    // },
    // Add more columns as needed
  ];

  const handleclick = (params) => {
    navigate(`${params}`);
  };

  React.useEffect(() => {
    callAction();
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: Loading ? "block" : "none",
          textAlign: "center",
        }}
      >
        <CircularProgress />
      </Box>

      {!Loading && (
        <Box sx={{ height: "85vh", width: "100%" }}>
          <DataGrid
            rows={parsedData}
            columns={columns}
            sx={{
              ".css-1iyq7zh-MuiDataGrid-columnHeaders": {
                backgroundColor: "#d7d8d8", // Replace with your desired background color code or name
                color: "black",
                fontWeight: 600,
                // Optional: Change text color for better contrast
              },
            }}
            // initialState={{
            //   pagination: {
            //     paginationModel: {
            //       pageSize: 5,
            //     },
            //   },
            // }}
            // pageSizeOptions={[20]}
            
            disableRowSelectionOnClick
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      )}
      <Modal open={open} setOpen={setOpen} />
    </>
  );
}
