// import * as React from "react";
// import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
// import { Button, CircularProgress, Grid } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import Modal from "../Dds/modal";
// import useAdmin from "../../../hooks/Admin";

// export default function CallAction() {
//   const [open, setOpen] = React.useState(false);
//   const [status, setStatus] = React.useState(true);
//   const [edit, setEdit] = React.useState([]);
//   const { Dds, getDdsAction, Loading } = useAdmin();

//   React.useEffect(() => {
//     getDdsAction();
//   }, []);
//   const columns = [
//     { field: "id", headerName: "ID", flex: 1 },
//     { field: "trunk", headerName: "Trunk", flex: 2 },
//     { field: "number", headerName: "Number", flex: 2 },
//     { field: "countryId", headerName: "Country ID", flex: 2 },
//     { field: "did_type", headerName: "DID Type", flex: 2 },
//     { field: "createdAt", headerName: "Created At", flex: 2 },
//     { field: "updatedAt", headerName: "Updated At", flex: 2 },
//     {
//       field: "action",
//       headerName: "Action",
//       headerAlign: "center",
//       width: 230,
//       renderCell: (params) => (
//         <Grid
//           sx={{
//             display: "flex",
//             justifyContent: "space-around",
//             alignItem: "center",
//             width: "100%",
//           }}
//         >
//           <Button
//             variant="contained"
//             startIcon={<EditIcon />}
//             onClick={() => handleEdit(params.row)}
//           >
//             Edit
//           </Button>
//           <Button
//             variant="contained"
//             color="error"
//             startIcon={<DeleteIcon />}
//             // onClick={() => handleDelete(params.row.id)} // Assuming you have a function to handle delete
//           >
//             Delete
//           </Button>
//         </Grid>
//       ),
//     },

//     // Add more columns as needed
//   ];
//   const handlopen = () => {
//     setOpen(true);
//     setStatus(true);
//   };
//   const handleEdit = (params) => {
//     setEdit(params);
//     setOpen(true);
//     setStatus(false);
//   };
//   return (
//     <>
//       <Box
//         sx={{
//           width: "100%",
//           display: Loading ? "block" : "none",
//           textAlign: "center",
//         }}
//       >
//         <CircularProgress />
//       </Box>
//       <Grid sx={{ my: 2, textAlign: "end" }}>
//         <Button variant="contained" startIcon={<AddIcon />} onClick={handlopen}>
//           Add
//         </Button>
//       </Grid>
//       {!Loading && (
//         <Box sx={{ height: "80vh", width: "100%" }}>
//           <DataGrid
//             rows={Dds}
//             columns={columns}
//             sx={{
//               ".css-1iyq7zh-MuiDataGrid-columnHeaders": {
//                 backgroundColor: "#d7d8d8", // Replace with your desired background color code or name
//                 color: "black", // Optional: Change text color for better contrast
//               },
//             }}
//             initialState={{
//               pagination: {
//                 paginationModel: {
//                   pageSize: 5,
//                 },
//               },
//             }}
//             pageSizeOptions={[5, 10, 15]}
//             disableRowSelectionOnClick
//           />
//         </Box>
//       )}
//       <Modal open={open} setOpen={setOpen} status={status} edit={edit} />
//     </>
//   );
// }

import React from "react";
import { Box } from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GeneralSettings from "../../../components/CallActionTab/GeneralSettings";

const CallAction = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        border: "1px solid gray",
        height: "calc(100vh - 114px)",
        padding: "8px",
      }}
    >
    <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="Quicksmart Features">
            <Tab label="Status" value="1" />
            <Tab label="Groups" value="2" />
            <Tab label="Campaigns" value="3" />
            <Tab label="General Settings" value="4" />
            <Tab label="SIP Trunk" value="5" />
          </TabList>
        </Box>
        <TabPanel value="1">Item One</TabPanel>
        <TabPanel value="2">Item Two</TabPanel>
        <TabPanel value="3">Item Three</TabPanel>
        <TabPanel value="4"><GeneralSettings /></TabPanel>
        <TabPanel value="5">Item Three</TabPanel>
      </TabContext>
    </Box>
  );
};

export default CallAction;
