import {
  Avatar,
  Box,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AgentModel from "../../components/AgentModel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function Index() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "start",
    color: theme.palette.text.secondary,
  }));
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item sx={{ mb: 2, px: 5, pt: 5, pb: 3, height: "88vh" }}>
            <Box>
              <Typography variant="h3" gutterBottom>
                API Keys
              </Typography>
              <Typography variant="subtitle1">
                Keep your API key confidential; avoid revealing it in browsers
                or any client-side code. For the safeguarding of your account,
                Retell AI reserves the right to deactivate any API key that is
                detected to have been disclosed in a public setting.
              </Typography>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                  {/* <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Payment Method" {...a11yProps(0)} />
                    <Tab label="Billing History" {...a11yProps(1)} />
                  </Tabs> */}
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid itemxs={12} sx={8} md={8} lg={6} xl={4}>
                      <Item>
                        <Typography variant="h5">Credentials</Typography>
                        <TextField
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleTogglePasswordVisibility}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
                {/* <CustomTabPanel value={value} index={0}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    sx={{ justifyContent: "start" }}
                  >
                    Add Payment Method
                  </Button>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Table /> */}
                {/* </CustomTabPanel> */}
              </Box>
            </Box>
          </Item>
        </Grid>
      </Grid>
      <AgentModel open={open} setOpen={setOpen} />
    </>
  );
}

export default Index;
