import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import {
  Box,
  DialogActions,
  DialogTitle,
  IconButton,
  InputLabel,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import useAdmin from "../../../hooks/Admin";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const validationSchema = Yup.object({
  context: Yup.string().required("Context is required"),
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
});

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));
const MyForm = ({ open, setOpen, status, edit }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const { AddcasesAction, editCasesAction } = useAdmin();
  const initialValues = {
    context: status ? "" : edit.context,
    name: status ? "" : edit.name,
    description: status ? "" : edit.description,
    type: status ? "" : edit.type,
    visibility: status ? false : edit.visibility ?? true,
  };
  const onSubmit = (values) => {
    // Here, you can submit the form data to your API or perform any other actions
    if (status) {
      AddcasesAction(values);
      handleClose();
    } else {
      const obj = { ...values, id: edit.id };
      editCasesAction(obj);
      handleClose();
    }
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            minWidth: { sm: "400px", md: "500px", lg: "600px", xl: "600px" },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {status ? "Add Cases" : "Edit Cases"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <InputLabel>Context</InputLabel>
                {/* <Field
                  as={TextareaAutosize}
                  label="Context"
                  id="context"
                  name="context"
                  errorClassName="error"
                  // fullWidth
                  margin="normal"
                  maxRows={3}
                  style={{ width: "100%" }}
                /> */}
                <Field name="context">
                  {({ field, form }) => (
                    <div style={{ maxHeight: "120px", overflow: "auto" }}>
                      <ReactQuill
                        theme="snow"
                        value={field.value}
                        onChange={(value) =>
                          form.setFieldValue("context", value)
                        }
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="context"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />

                <Field
                  as={TextField}
                  label="Name"
                  id="name"
                  name="name"
                  size="small"
                  errorClassName="error"
                  fullWidth
                  margin="normal"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />
                <Field
                  as={TextField}
                  label="Description"
                  id="description"
                  name="description"
                  size="small"
                  errorClassName="error"
                  fullWidth
                  margin="normal"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                  style={{ color: "red" }}
                />
                <Field
                  as={Select}
                  label="Type"
                  id="type"
                  name="type"
                  size="small"
                  native
                  fullWidth
                  margin="normal"
                >
                  <option value="inbound">Inbound</option>
                  <option value="outbound">Outbound</option>
                </Field>
                <FormControlLabel
                  sx={{ display: "flex" }}
                  control={
                    <Field
                      as={Switch}
                      id="visibility"
                      name="visibility"
                      defaultChecked={!status ? edit.visibility : false}
                      color="primary"
                    />
                  }
                  label="Visibility"
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  {status ? "Submit" : "Update"}
                </Button>
              </Form>
            </Formik>
          </DialogContent>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default MyForm;
