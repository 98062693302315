import React from "react";
import { Container, Grid, Typography, Paper, Avatar, Box } from "@mui/material";
import logo from "../../assets/images/logo/quicksmart-1_480.png";

const YourComponent = () => {
  return (
    <>
      <Box
        sx={{
          padding: {
            xs: "10px 20px",
            sm: "10px 20px",
            md: "13px 20px",
            lg: "18px 20px",
            xl: "104px 90px",
          },
          background: "#1a1a1a",
        }}
      >
        {/* <Container> */}
        <Box sx={{ textAlign: "center", color: "white", my: 10 }}>
          <Typography variant="h2" component="h2" gutterBottom>
            About QuickSmart
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid
            item
            xs={10}
            lg={10}
            xl={10}
            margin="auto"
            sx={{ color: "white", textAlign: "justify" }}

            // display={{ xs: "none", lg: "block" }}
          >
            <Typography variant="h4" component="h2" gutterBottom>
              Welcome to the Future with QuickSmart AI
            </Typography>
            <Typography variant="body1" paragraph>
              QuickSmart AI is not just leading the evolution of SIP VoIP
              telephony; we're redefining it through the transformative power of
              artificial intelligence. Born from a decade of pioneering work in
              the VoIP domain and officially established in India four years
              ago, our company has been a beacon of innovation, focusing on
              leveraging AI to elevate communication technologies to
              unprecedented levels of efficiency and intelligence.
            </Typography>
            <Typography variant="h4" paragraph>
              Our Core Expertise
            </Typography>
            <Typography variant="body1" paragraph>
              With over 10 years of dedicated experience in the field, the team
              at QuickSmart AI possesses unparalleled expertise in integrating
              AI with SIP VoIP telephony. This unique blend of knowledge enables
              us to offer solutions that are not just advanced but are
              predictive, adaptive, and capable of revolutionizing how
              businesses interact internally and with their customers.
            </Typography>
            <Typography variant="h4" paragraph>
              Revolutionizing Communication with AI
            </Typography>
            <Typography variant="body1" paragraph>
              Our mission is to transform business communication through
              AI-driven SIP VoIP solutions that offer smarter, faster, and more
              reliable ways to connect. Here's what sets us apart:
            </Typography>
            <Typography variant="body1" paragraph>
              - Intelligent VoIP Services: Our AI-powered telephony services go
              beyond traditional voice communication, offering features like
              smart call routing, voice recognition, and real-time analytics to
              enhance decision-making and customer satisfaction.
            </Typography>
            <Typography variant="body1" paragraph>
              - Optimized Inbound and Outbound Processes: Leveraging AI, we
              streamline your communication processes, ensuring that your
              inbound and outbound calls are handled with unprecedented
              efficiency and personalization.
            </Typography>
            <Typography variant="body1" paragraph>
              - Tailored AI Solutions: Recognizing the unique challenges and
              opportunities of your business, we offer customized AI
              integrations that seamlessly blend with your existing VoIP
              infrastructure, providing a truly intelligent communication
              ecosystem.
            </Typography>
            <Typography variant="body1" paragraph>
              - Reliability and Support: At QuickSmart AI, we stand by the
              quality of our solutions with 24/7 support and a commitment to
              reliability, ensuring your business communications are always at
              their best.
            </Typography>

            <Typography variant="h4" component="h2" gutterBottom>
              Why QuickSmart AI?
            </Typography>
            <Typography variant="body1" paragraph>
              - Pioneering AI Integration: Our forward-thinking approach to
              integrating AI with VoIP telephony puts your business ahead of the
              curve, ready to embrace the future of communication.
            </Typography>
            <Typography variant="body1" paragraph>
              - Customized AI-Driven Strategies: We believe in solutions that
              are as unique as your business. Our AI-driven strategies are
              designed to meet your specific needs, ensuring optimal performance
              and satisfaction.
            </Typography>
            <Typography variant="body1" paragraph>
              - Expertise You Can Trust: With a solid foundation in the VoIP
              domain and a passion for AI, our team is equipped to deliver
              solutions that exceed expectations.
            </Typography>
            <Typography variant="body1" paragraph>
              - Partnership for Innovation: Choosing QuickSmart AI means
              partnering with leaders in communication technology innovation.
              We're dedicated to your success, offering not just services but a
              pathway to transforming your business communication.
            </Typography>
            <Typography variant="h4" component="h2" gutterBottom>
              Embark on Your AI Journey with Us
            </Typography>
            <Typography variant="body1" paragraph>
              QuickSmart AI is more than a provider of SIP VoIP services; we're
              your gateway to the future of intelligent communication. Discover
              the potential of AI-enhanced telephony and how it can empower your
              business to communicate more effectively, efficiently, and
              reliably. Contact us today to explore the intelligent solutions
              that await with QuickSmart AI.
            </Typography>
          </Grid>

          {/* </Grid>
        </Grid> */}
        </Grid>
        {/* </Container> */}
      </Box>
    </>
  );
};

export default YourComponent;
