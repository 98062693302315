import { Spinner } from "react-bootstrap";
import { Headphones } from "./Headphones";
import { useStyles } from "../styles";
import { Button, Typography } from "@mui/material";
import { clsx } from "clsx";


export const InitialLoad = ({ fn, connecting = true }) => {
  const classes = useStyles()

  return (
    <>
      <div className="col-start-1 col-end-13 sm:col-start-2 sm:col-end-12 md:col-start-3 md:col-end-11 lg:col-start-1 lg:col-end-13 p-3 mb-1/2">
        <Typography className={clsx(classes.section_2_title, 'text-center')} variant="h3">Meet our smart AI assistant.</Typography>
        <button
          disabled={connecting}
          onClick={() => !connecting && fn()}
          type="button"
          className="relative block w-full glass p-6 sm:p-8 lg:p-12 rounded-xl"
        >
          <span className="mt-4 block font-semibold flex justify-center">

            <Button className={classes.section_2_btn}>
              {connecting ? (
                <div className="w-auto h-full items-center flex justify-center opacity-40 cursor-not-allowed">
                  <Spinner size={"sm"} className="-mt-1 mr-2" />
                  Connecting...
                </div>
              ) : (
                <> Start Conversation</>
              )}</Button>


          </span>
        </button>
        <span className="text-center mt-1 block text-sm text-gray-100/70 w-50 m-auto">
          <Headphones /> For optimal enjoyment, we recommend using headphones
          while using this application. Minor bugs and annoyances may appear
          while using this demo. Pull requests are welcome.
        </span>
        <span className="text-center w-50 m-auto w-40 mt-2 block text-sm text-gray-100/50">
          By starting a conversation, I accept QuickSmart's&nbsp;
          <i>Terms of Use </i>
          &nbsp;
          and acknowledge the
          &nbsp;
          <i>Privacy Policy</i>
        </span>
      </div>
    </>
  );
};
