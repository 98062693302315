import React from "react";
import HeroHeader from "../../components/HeroHeader";
import HeroFooter from "../../components/HeroFooter";
import MainContent from "../../components/LandingPage/MainContent";
import Usecase from "../../components/LandingPage/Usecase";
import Aimade from "../../components/LandingPage/Aimade";
import UniqueFeature from "../../components/LandingPage/UniqueFeature";
import Pricing from "../../components/LandingPage/Pricing";
import Frequently from "../../components/LandingPage/Frequently";
import FormBox from "../../components/LandingPage/FormBox";

const HeroScreen = () => {
  return (
    <>
      <HeroHeader />
      <MainContent />
      <Usecase />
      <FormBox />
      <Aimade />
      <UniqueFeature />
      <Pricing />
      <Frequently />
      <HeroFooter />
    </>
  );
};

export default HeroScreen;
