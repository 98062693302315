import React from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import Login from "../../pages/Login";
import Registration from "../../pages/Registration";
import styles from "./index.module.scss";

function LoginTab() {
  return (
    <Container>
      <div className={styles.login_container}>
        <div className={styles.login_Box}>
          <Login />
        </div>
      </div>
    </Container >
  );
}

export default LoginTab;
