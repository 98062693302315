import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as adminAPI from "../../api/Admin";
import { toast } from "react-toastify";

const getAdminState = (state) => state.admin;

const Allcall = createAsyncThunk("admin/call", async (params) => {
  const res = await adminAPI.getCall(params);
  return res.data;
});

const Allcountry = createAsyncThunk("admin/country", async (params) => {
  const res = await adminAPI.getCountry(params);
  return res.data;
});

const Addcountry = createAsyncThunk("admin/Addcountry", async (params) => {
  const res = await adminAPI.AddCountry(params);
  return res.data;
});

const Allcases = createAsyncThunk("admin/case", async () => {
  const res = await adminAPI.getCases();
  return res.data;
});

const Addcases = createAsyncThunk("admin/Addcases", async (params) => {
  const res = await adminAPI.AddCases(params);
  return res.data;
});
const Alldds = createAsyncThunk("admin/dds", async () => {
  const res = await adminAPI.getDds();
  return res.data;
});
const AddDds = createAsyncThunk("admin/AddDds", async (params) => {
  const res = await adminAPI.AddDds(params);
  return res.data;
});
const getChat = createAsyncThunk("admin/getChat", async (params) => {
  const res = await adminAPI.getChat(params);
  return res.data;
});
const getPostCallAnalysis = createAsyncThunk(
  "admin/getPostCallAnalysis",
  async (params) => {
    const res = await adminAPI.getPostCallAnalysis(params);
    return res.data;
  }
);
const editCases = createAsyncThunk("admin/editCases", async (params) => {
  const res = await adminAPI.editcases(params);
  return res.data;
});
const editdids = createAsyncThunk("admin/editdids", async (params) => {
  const res = await adminAPI.editdids(params);
  return res.data;
});
const getCustomer = createAsyncThunk("admin/getCustomer", async (params) => {
  const res = await adminAPI.getCustomer(params);
  return res.data;
});
const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ email, user }) => {
    if (user === null) return;
  }
);
const adminSlice = createSlice({
  name: "admin",
  initialState: {
    country: [],
    cases: [],
    Dds: [],
    call: [],
    chat: [],
    customer: [],
    isAuth: false,
    postCallData: null,
    token: null,
    status: "fetchingFromLocalStore", // "loggingIn" | "loggingOut" | "sessionExpired"
    expires: null,
    error: null,
    admin: false,
    campaign: null,
    callAction: null,
    ui: {
      login: { loading: false },
      msg: "",
    },
  },

  reducers: {
    persistLogin: (state) => {
      // if (isLocalStorageAvailable()) {
      // const token = localStorage.getItem("persist:auth");
      // const expires = localStorage.getItem("expires");
      // if (token) {
      //   state.isAuth = true;
      //   state.token = token;
      //   if (expires) {
      //     state.expires = expires;
      //   }
      // }
      state.status = "idle";
      // }
    },
    userregister: (state) => {
      state.isAuth = false;
      state.token = null;
      state.expires = null;
      state.status = "unregistered";
    },
    logout: (state) => {
      state.isAuth = false;
      state.token = null;
      state.expires = null;
      state.status = "loggingOut";
    },
    sessionExpired: (state) => {
      // if (isLocalStorageAvailable()) {
      //   localStorage.removeItem("token");
      //   localStorage.removeItem("expires");
      // }
      state.isAuth = false;
      state.token = null;
      state.status = "sessionExpired";
      state.expires = null;
      state.error = "sessionExpired";
    },
  },

  extraReducers: {
    // login
    [Allcall.pending]: (state) => {
      state.status = "loggingIn";
      state.ui.login.loading = true;
    },

    [Allcall.fulfilled]: (state, action) => {
      state.status = "idle";
      state.isAuth = true;
      state.call = action.payload;
      state.ui.login.loading = false;
    },

    [Allcall.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.error.message;
      state.ui.login.loading = false;
    },
    [Allcountry.pending]: (state) => {
      state.status = "loggingIn";
      state.ui.login.loading = true;
    },

    [Allcountry.fulfilled]: (state, action) => {
      state.status = "success";
      state.isAuth = true;
      state.country = action.payload;
      state.ui.login.loading = false;
    },

    [Allcountry.rejected]: (state, action) => {
      state.status = "Error";
      state.error = action.error.message;
      state.ui.login.loading = false;
    },
    [Addcountry.pending]: (state) => {
      state.status = "loggingIn";
      state.ui.login.loading = true;
    },

    [Addcountry.fulfilled]: (state, action) => {
      state.status = "success";
      state.toast = true;
      state.isAuth = true;
      state.ui.login.loading = false;
      toast.success("Country added successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // state.country = action.payload;
    },

    [Addcountry.rejected]: (state, action) => {
      state.status = "Error";
      state.toast = false;
      state.error = action.error.message;
      state.ui.login.loading = false;
      toast.error("Error Notification !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [Allcases.pending]: (state) => {
      state.status = "loggingIn";
      state.ui.login.loading = true;
    },

    [Allcases.fulfilled]: (state, action) => {
      state.status = "idle";
      state.isAuth = true;
      state.cases = action.payload;
      state.ui.login.loading = false;
    },

    [Allcases.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.error.message;
      state.ui.login.loading = false;
    },
    [Addcases.pending]: (state) => {
      state.status = "loggingIn";
      state.ui.login.loading = true;
    },

    [Addcases.fulfilled]: (state, action) => {
      state.status = "success";
      state.isAuth = true;
      state.ui.login.loading = false;
      toast.success("Cases added successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      //  state.cases = action.payload;
    },

    [Addcases.rejected]: (state, action) => {
      state.status = "Error";
      state.error = action.error.message;
      state.ui.login.loading = false;
      toast.error("Error Notification !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [Addcases.pending]: (state) => {
      state.status = "loggingIn";
      state.ui.login.loading = true;
    },

    [editCases.fulfilled]: (state, action) => {
      state.status = "success";
      state.isAuth = true;
      state.ui.login.loading = false;
      toast.success("Cases update successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      //  state.cases = action.payload;
    },

    [editCases.rejected]: (state, action) => {
      state.status = "Error";
      state.error = action.error.message;
      state.ui.login.loading = false;
      toast.error("Error Notification !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },

    [editCases.pending]: (state) => {
      state.status = "loggingIn";
      state.ui.login.loading = true;
    },

    [Alldds.fulfilled]: (state, action) => {
      state.status = "success";
      state.isAuth = true;
      state.Dds = action.payload;
      state.ui.login.loading = false;
    },

    [Addcases.rejected]: (state, action) => {
      state.status = "Error";
      state.error = action.error.message;
      state.ui.login.loading = false;
    },
    [AddDds.pending]: (state) => {
      state.status = "loggingIn";
      state.ui.login.loading = true;
    },

    [AddDds.fulfilled]: (state, action) => {
      state.status = "success";
      state.isAuth = true;
      // state.Dds = action.payload;
      toast.success("Country added successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      state.ui.login.loading = false;
    },

    [AddDds.rejected]: (state, action) => {
      state.status = "Error";
      state.error = action.error.message;
      state.ui.login.loading = false;
      toast.error("Error Notification !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    [editdids.fulfilled]: (state, action) => {
      state.status = "success";
      state.isAuth = true;
      state.ui.login.loading = false;
      toast.success("Cases update successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      //  state.cases = action.payload;
    },

    [editdids.rejected]: (state, action) => {
      state.status = "Error";
      state.error = action.error.message;
      state.ui.login.loading = false;
      toast.error("Error Notification !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },

    [editdids.pending]: (state) => {
      state.status = "loggingIn";
      state.ui.login.loading = true;
    },
    [getChat.pending]: (state) => {
      state.status = "loggingIn";
      state.ui.login.loading = true;
    },

    [getChat.fulfilled]: (state, action) => {
      state.status = "idle";
      state.isAuth = true;
      state.chat = action.payload;
      state.ui.login.loading = false;
    },

    [getChat.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.error.message;
      state.ui.login.loading = false;
      state.chat = [];
    },
    [getPostCallAnalysis.pending]: (state) => {
      state.status = "loggingIn";
      state.ui.login.loading = true;
    },

    [getPostCallAnalysis.fulfilled]: (state, action) => {
      state.status = "idle";
      state.isAuth = true;
      state.postCallData = action.payload;
      state.ui.login.loading = false;
    },

    [getPostCallAnalysis.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.error.message;
      state.ui.login.loading = false;
      state.postCallData = null;
    },
    [getCustomer.pending]: (state) => {
      state.status = "loggingIn";
      state.ui.login.loading = true;
    },

    [getCustomer.fulfilled]: (state, action) => {
      state.status = "idle";
      state.isAuth = true;
      state.customer = action.payload;
      state.ui.login.loading = false;
    },

    [getCustomer.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.error.message;
      state.ui.login.loading = false;
    },
    // resetPassword
    [resetPassword.pending]: (state) => {
      state.status = "resetingPassword";
      state.ui.login.loading = true;
      state.ui.error = "";
      state.ui.msg = "";
    },

    [resetPassword.fulfilled]: (state, action) => {
      state.status = "idle";
      state.ui.login.loading = false;
      state.ui.msg = action.payload.msg;
      state.ui.login.loading = false;
    },

    [resetPassword.rejected]: (state, action) => {
      state.status = "idle";
      state.ui.error = action.error.message;
      state.ui.login.loading = false;
    },
  },
});

const { persistLogin, logout, sessionExpired } = adminSlice.actions;

export default adminSlice.reducer;

export {
  persistLogin,
  Allcall,
  Allcountry,
  Addcountry,
  Allcases,
  Addcases,
  editCases,
  Alldds,
  AddDds,
  logout,
  sessionExpired,
  resetPassword,
  getChat,
  getPostCallAnalysis,
  editdids,
  getCustomer,
  getAdminState,
};
