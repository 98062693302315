import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { AddCountry } from "../../../api/Admin";
import {
  Box,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Select,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import useAdmin from "../../../hooks/Admin";
import { ToastContainer, toast } from "react-toastify";
const validationSchema = Yup.object({
  trunk: Yup.string().required("Trunk is required"),
  number: Yup.string().required("Number is required"),
  countryId: Yup.number().required("Country ID is required"),
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const MyForm = ({ open, setOpen, status, edit }) => {
  const { addDdsAction, editDidsAction } = useAdmin();
  const initialValues = {
    trunk: status ? "" : edit.trunk,
    number: status ? "" : edit.number,
    countryId: status ? "" : edit.countryId,
    type: status ? "" : edit.type,
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values) => {
    if (status) {
      addDdsAction(values);
      handleClose();
    } else {
      const obj = { ...values, id: edit.id };
      editDidsAction(obj);
      handleClose();
    }
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Box
          sx={{
            minWidth: { sm: "400px", md: "500px", lg: "600px", xl: "600px" },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {status ? "Add Dids" : "Edit Dids"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleChange, values }) => (
                <Form>
                  <Field
                    name="trunk"
                    type="text"
                    size="small"
                    as={TextField}
                    label="Trunk"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.trunk}
                  />
                  <ErrorMessage
                    name="trunk"
                    component="div"
                    style={{ color: "red" }}
                  />

                  <Field
                    name="number"
                    type="text"
                    size="small"
                    as={TextField}
                    label="Number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.number}
                  />
                  <ErrorMessage
                    name="number"
                    component="div"
                    style={{ color: "red" }}
                  />

                  <Field
                    name="countryId"
                    type="text"
                    as={TextField}
                    size="small"
                    label="Country ID"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.countryId}
                  />
                  <ErrorMessage
                    name="countryId"
                    component="div"
                    style={{ color: "red" }}
                  />
                  <Field
                    as={Select}
                    label="Type"
                    id="type"
                    name="type"
                    size="small"
                    native
                    fullWidth
                    margin="normal"
                  >
                    <option value="inbound">Inbound</option>
                    <option value="outbound">Outbound</option>
                  </Field>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </DialogContent>
          {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default MyForm;
