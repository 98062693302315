export const UserAvatar = () => {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M11.5625 12.25C11.5625 10.062 12.4317 7.96354 13.9789 6.41637C15.526 4.86919 17.6245 4 19.8125 4C22.0005 4 24.099 4.86919 25.6461 6.41637C27.1933 7.96354 28.0625 10.062 28.0625 12.25C28.0625 14.438 27.1933 16.5365 25.6461 18.0836C24.099 19.6308 22.0005 20.5 19.8125 20.5C17.6245 20.5 15.526 19.6308 13.9789 18.0836C12.4317 16.5365 11.5625 14.438 11.5625 12.25Z"
        fill="#13EF93"
      />
      <path
        d="M5.375 35.0857C5.375 28.7371 10.5184 23.5938 16.867 23.5938H22.758C29.1066 23.5938 34.25 28.7371 34.25 35.0857C34.25 36.1428 33.3928 37 32.3357 37H7.28926C6.23223 37 5.375 36.1428 5.375 35.0857Z"
        fill="#13EF93"
      />
    </svg>
  );
};
