import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { AddCountry } from "../../../api/Admin";
import {
  Box,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import useAdmin from "../../../hooks/Admin";
import { ToastContainer, toast } from "react-toastify";
const validationSchema = Yup.object({
  country: Yup.string().required("Country is required"),
  code: Yup.string().required("Code is required"),
  // Add validation for other fields as needed
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const MyForm = ({ open, setOpen }) => {
  const { AddcountryAction, Toaststatus2 } = useAdmin();
  const handleClose = () => {
    setOpen(false);
  };
  console.log(Toaststatus2, "Toaststatus2");

  return (
    <React.Fragment>
      <ToastContainer />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Box
          sx={{
            minWidth: { sm: "400px", md: "500px", lg: "600px", xl: "600px" },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Add Country
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Formik
              initialValues={{
                country: "",
                code: "",
                visibility: "",
                // Initialize other fields here
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                // Handle form submission here
                const obj = {
                  country: values.country,
                  countryCode: values.code,
                  visibility: values.visibility,
                };
                console.log("Form values:", obj);
                // fetchCountryData(obj);
                AddcountryAction(obj);

                handleClose(); // Close the dialog after submission
              }}
            >
              {({ handleChange, values }) => (
                <Form>
                  <TextField
                    type="text"
                    name="country"
                    size="small"
                    label="country"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={values.country}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="country"
                    style={{ color: "red" }}
                    component="div"
                  />

                  <TextField
                    type="text"
                    name="code"
                    label="Code"
                    size="small"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={values.code}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="code"
                    style={{ color: "red" }}
                    component="div"
                  />

                  <Field
                    type="checkbox"
                    name="visibility"
                    as={Switch}
                    sx={{ display: "flex" }}
                  />

                  {/* Add other fields as needed */}
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </DialogContent>
          {/* <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Save changes
            </Button>
          </DialogActions> */}
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default MyForm;
