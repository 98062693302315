
import { useCallback, useEffect } from "react";

import { Download } from "./Download";
import { MicrophoneIcon } from "./icons/MicrophoneIcon";
import { SendIcon } from "./icons/SendIcon";
import { Settings } from "./Settings";
import { useMicrophone } from "../../../context/Microphone";
import { useNowPlaying } from "react-nowplaying";
import { Tooltip } from "@mui/material";
import Waveform from "./WaveForm";

export const Controls = ({
  input,
  handleSubmit,
  handleInputChange,
  messages,
}) => {
  const { startMicrophone, stopMicrophone, microphoneOpen, stream } = useMicrophone();

  useEffect(() => {
    startMicrophone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const microphoneToggle = useCallback(
    async (e) => {
      e.preventDefault();

      if (microphoneOpen) {
        stopMicrophone();
      } else {
        startMicrophone();
      }
    },
    [microphoneOpen, startMicrophone, stopMicrophone]
  );

  const { stop: stopAudio } = useNowPlaying();

  const submitter = useCallback(
    (e) => {
      handleSubmit(e);
      stopAudio();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [stopAudio, handleSubmit]
  );

  return (
    <form onSubmit={submitter}>
      <div className="relative">
        <div className="absolute w-full -top-[4.5rem] py-4 flex justify-between">
          <Settings />
          <Download messages={messages} />
        </div>
        <div className="relative">
          <div className="absolute top-0 left-0 w-50 m-auto h-100 absolute top-1/2 left-1/2 transform -translate-x-1/2 translate-y-1.2">
            <Waveform stream={stream} />
          </div>
          <div className="flex justify-center z-10" >
            <span
              className={`rounded-full p-0.5 ${microphoneOpen
                ? "bg-gradient-to-r to-[#13EF93]/50 from-red-500"
                : "bg-gradient-to-r to-[#13EF93]/50 from-[#149AFB]/80"
                }`}
            >
              <Tooltip showArrow title="Toggle microphone on/off.">
                <a
                  href="#"
                  onClick={(e) => microphoneToggle(e)}
                  className={`w-20 sm:w-24 py-4 px-2 sm:px-8 rounded-full font-bold bg-[#000000] text-light-900 text-sm sm:text-base flex items-center justify-center group`}
                >
                  {microphoneOpen && (
                    <div className="w-auto items-center justify-center hidden sm:flex absolute shrink-0">
                      <MicrophoneIcon
                        micOpen={microphoneOpen}
                        className="h-6 animate-ping-short"
                      />
                    </div>
                  )}
                  <div className="w-auto flex items-center justify-center shrink-0">
                    <MicrophoneIcon micOpen={microphoneOpen} className="h-6" />
                  </div>
                  <span>
                    {/* Add text or icon for the microphone button */}
                  </span>
                </a>
              </Tooltip>
            </span>
          </div>
        </div>

      </div>
    </form>
  );
};
