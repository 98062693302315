export const Headphones = () => {
  return (
    <>
      <svg
        viewBox="0 0 20 19"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-current w-4 inline mr-2"
      >
        <path d="M10 0.75C15.5859 0.75 19.8047 5.4375 20 10.75V14.8125C20 15.3594 19.5703 15.75 19.0625 15.75C18.5156 15.75 18.125 15.3594 18.125 14.8125V10.75C18.125 6.29688 14.4531 2.66406 10 2.66406C5.50781 2.66406 1.875 6.29688 1.875 10.75V14.8125C1.875 15.3594 1.36719 15.75 0.898438 15.75C0.390625 15.75 0 15.3594 0 14.8125V10.75C0.15625 5.4375 4.375 0.75 10 0.75ZM6.25 10.75C6.91406 10.75 7.5 11.3359 7.5 12.0391V17C7.5 17.7031 6.91406 18.25 6.25 18.25H5.625C4.21875 18.25 3.125 17.1562 3.125 15.75V13.2891C3.125 11.8828 4.21875 10.75 5.625 10.75H6.25ZM14.375 10.75C15.7422 10.75 16.875 11.8828 16.875 13.2891V15.75C16.875 17.1562 15.7422 18.25 14.375 18.25H13.75C13.0469 18.25 12.5 17.7031 12.5 17V12.0391C12.5 11.3359 13.0469 10.75 13.75 10.75H14.375Z" />
      </svg>
    </>
  );
};
