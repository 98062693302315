import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

// Styled components for customization
const TransparentAppBar = styled(AppBar)({
    backgroundColor: 'transparent',
    boxShadow: 'none',
});

const Logo = styled('img')({
    height: '40px',
    marginRight: 'auto',
});

function Header() {
    return (
        <TransparentAppBar position="static">
            <Toolbar>
                <Logo src="/assets/images/quicksmart_demo.png" alt="Logo" />
                <Typography variant="h6" component="div" fontWeight={500} sx={{ flexGrow: 1 }} color={'white'}>
                    QuickSmart
                </Typography>
            </Toolbar>
        </TransparentAppBar>
    );
}

export default Header;