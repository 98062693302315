import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
export const CardData = [
  {
    icon: <AccessAlarmIcon />,
    title: "Turbo latency optimizations",
    detail:
      "Optimized GPU inference, intelligent caching, low-latency audio streaming.",
  },
  {
    icon: <AccessAlarmIcon />,
    title: "Interruptions",
    detail:
      "A person knows when to stop speaking, your voicebot should too.",
  },
  {
    icon: <AccessAlarmIcon />,
    title: "Proprietary endpointing model",
    detail:
      "With great speed comes great responsibility, your users shouldn’t be interrupted when they pause.",
  },
  {
    icon: <AccessAlarmIcon />,
    title: "Scale to 1M+ concurrent calls",
    detail:
      "Carefully designed Kubernetes cluster, with scalability, and high availability built-in.",
  },
  {
    icon: <AccessAlarmIcon />,
    title: "Function calling",
    detail:
      "Give your voicebot superpowers–booking appointments, data lookup, form-filling, and so much more.",
  },
  {
    icon: <AccessAlarmIcon />,
    title: "WebRTC streaming",
    detail:
      "Same protocol used by Google Meets and Microsoft Teams for the lowest latency and highest fault tolerance.",
  },
  {
    icon: <AccessAlarmIcon />,
    title: "Onprem provider deployments",
    detail:
      "Avoid the latency spikes and unreliability of shared infrastructure.",
  },
  {
    icon: <AccessAlarmIcon />,
    title: "Multilingual support",
    detail:
      "Create voicebots in your users’ tongues–English, Spanish, German, Hindi, Portuguese, and 100+ others.",
  },
  {
    icon: <AccessAlarmIcon />,
    title: "Private internet backbone",
    detail:
      "Avoid network congestion on the public internet for your users around the world.",
  },
];


export const Chiplable = [
  {
    label: "HR Pre-Screening"
  },
  {
    label: "Real Estate"
  },
  {
    label: "Insurance Policy Sales"
  },
  {
    label: "Medical Appointment Booking"
  },
  {
    label: "Product Support/Service"
  },
  {
    label: "Food Order"
  },
  {
    label: "Lead Qualification"
  },
  {
    label: "Recruitment"
  },
]