import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import {
  Box,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import useAdmin from "../../../hooks/Admin";
import { ToastContainer, toast } from "react-toastify";

const validationSchema = Yup.object({
  link: Yup.string().url("Enter a valid URL").required("URL is required"),
  number: Yup.number().required("Number is required"),
  name: Yup.string().required("Name is required"),
  // Add validation for other fields as needed
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  minWidth: "600px !important",
}));
const MyForm = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Box
          sx={{
            minWidth: { sm: "400px", md: "500px", lg: "600px", xl: "600px" },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Add Call
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Formik
              initialValues={{
                link: "",
                number: "",
                name: "",
                // Initialize other fields here
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                // Handle form submission here
                handleClose(); // Close the dialog after submission
              }}
            >
              {({ handleChange, values }) => (
                <Form>
                  <TextField
                    type="text"
                    name="link"
                    label="Link"
                    size="small"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={values.link}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="link"
                    style={{ color: "red" }}
                    component="div"
                  />

                  <TextField
                    type="number"
                    name="number"
                    size="small"
                    label="Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={values.number}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="number"
                    style={{ color: "red" }}
                    component="div"
                  />

                  <TextField
                    type="text"
                    name="name"
                    label="Name"
                    size="small"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="name"
                    style={{ color: "red" }}
                    component="div"
                  />

                  {/* Add other fields as needed */}
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Save changes
            </Button>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default MyForm;
