import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Button, CircularProgress, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "../Cases/modal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getCases } from "../../../api/Admin";
import useAdmin from "../../../hooks/Admin";

export default function DataGridDemo() {
  const { Customer, getCustomerAction, Loading } = useAdmin();
  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 2 },
    { field: "email", headerName: "Email", flex: 2 },
    { field: "contact", headerName: "Contact", flex: 2 },
    { field: "uuid", headerName: "UUID", flex: 2 },
    { field: "countryId", headerName: "Country ID", flex: 1 },
    { field: "usecaseId", headerName: "Usecase ID", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    { field: "createdAt", headerName: "Created At", flex: 2 },
    { field: "updatedAt", headerName: "Updated At", flex: 2 },
    // {
    //   field: "Edit",
    //   headerName: "Edit",
    //   flex: 2,
    //   renderCell: (params) => (
    //     <Button
    //       variant="contained"
    //       startIcon={<EditIcon />}
    //       onClick={() => handleEdit(params.row)}
    //     >
    //       Edit
    //     </Button>
    //   ),
    // },
    // {
    //   field: "Delete",
    //   headerName: "Delete",
    //   width: 150,
    //   renderCell: (params) => (
    //     <Button variant="contained" color="success" startIcon={<DeleteIcon />}>
    //       Delete
    //     </Button>
    //   ),
    // },
    // Add more columns as needed
  ];
  React.useEffect(() => {
    getCustomerAction();
  }, []);

  const sortedCustomer = React.useMemo(() => {
    return [...Customer]?.sort((a, b) => b.id - a.id);
  }, [Customer]);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: Loading ? "block" : "none",
          textAlign: "center",
        }}
      >
        <CircularProgress />
      </Box>

      {!Loading && (
        <Box sx={{ width: "100%", height: "85vh" }}>
          <DataGrid
            rows={sortedCustomer}
            columns={columns}
            sx={{
              ".css-1iyq7zh-MuiDataGrid-columnHeaders": {
                backgroundColor: "#d7d8d8", // Replace with your desired background color code or name
                color: "black", // Optional: Change text color for better contrast
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            pageSizeOptions={[15, 20, 25]}
            disableRowSelectionOnClick
          />
        </Box>
      )}
      {/* <Modal open={open} setOpen={setOpen} status={status} edit={edit} /> */}
    </>
  );
}
