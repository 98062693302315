import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";

// Custom styled components
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.grey[700],

    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center", // Centering content horizontally
    alignItems: "center", // Centering content vertically
    minHeight: "400px", // Set minimum height for the dialog content
    minWidth: "400px", // Set minimum width for the dialog content
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  // Ensure the dialog as a whole is large enough
  "& .MuiPaper-root": {
    minWidth: "500px", // Minimum width of the whole dialog
    minHeight: "500px", // Minimum height of the whole dialog
  },
}));

const HighlightedText = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.grey[700],
  color: "white",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  textAlign: "center", // Ensure the text is centered
  width: "100%", // Make sure the background covers the whole text
}));

export default function CustomizedDialogs({ open, setOpen }) {
  const theme = useTheme();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            textAlign: "center",
            backgroundColor: theme.palette.grey[900],
            color: "white",
          }}
          id="customized-dialog-title"
        >
          Try Out Incoming Call Feature
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box display="flex" justifyContent="center">
            <HighlightedText variant="h2" gutterBottom>
              Trial Call:{" "}
              <Typography fontWeight={900} variant="h3">
                +91 12135-09200
              </Typography>
            </HighlightedText>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ backgroundColor: theme.palette.grey[900], color: "white" }}
        >
          <Button
            autoFocus
            onClick={handleClose}
            variant="contained"
            color="error"
          >
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
