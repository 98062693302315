import React from "react";
import { Box, Button, Grid } from "@mui/material";
import UniqueTop from "../../../assets/images/Homepage/uniquefeatues.webp";
import BlurImage from "../../../assets/images/Homepage/featuresblur.webp";
import styles from "./index.module.scss";
import { CardData } from "../../../utils/HomescreenCard";

const UniqueFeature = () => {
  return (
    <section className={styles.unique_main_container}>
      <Grid className={styles.unique_sub_container}>
        <Grid className={styles.img_container}>
          <img src={UniqueTop} alt="UniqueTop" />
        </Grid>
        <Grid className={styles.unique_sub_child_container}>
          <Grid className={styles.btn_main_container}>
            <Button className={styles.btn_container}>Unique Features</Button>
          </Grid>
          <Grid className={styles.text_container}>
            <h1>
              Built to make your voicebot talk and respond just like a real person.
            </h1>
          </Grid>
        </Grid>
        <Grid container className={styles.card_main_component}>
          <Grid className={styles.blur_img_container}>
            <img src={BlurImage} alt="BlurImage" />
          </Grid>
          {CardData?.map((item, index) => {
            return (
              <Grid
                key={index}
                item
                sm={12}
                md={6}
                lg={4}
                className={styles.card_comaponent}
              >
                <Grid className={styles.card_sub_component}>
                  <Grid sx={{ color: "#ADDBE6" }}>{item?.icon}</Grid>
                  <Grid>
                    <Grid className={styles.card_data}>
                      <p>{item?.title}</p>
                    </Grid>
                    <Grid className={styles.card_detail}>
                      <p>{item?.detail}</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </section>
  );
};

export default UniqueFeature;
