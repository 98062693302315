import React from "react";
import FooterBottom from "../../assets/images/Homepage/FooterBottom.svg";
import FooterLogo from "../../assets/images/logo/quicksmart__1__720.png";
import { Link, Grid, Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";
import styles from "./index.module.scss";

const HeroFooter = () => {
  return (
    <Grid className={styles.footer_main_component}>
      <Grid className={styles.footer_sub_component}>
        <Grid container className={styles.footer_row}>
          <Grid item sx={{ height: "52px" }} xs={12} md={4} lg={6}>
            <img src={FooterLogo} alt="FooterLogo" style={{ height: "100%" }} />
          </Grid>
          <Grid item container direction="row" xs={12} md={8} lg={6} component={'footer'}>
            <Grid sx={{ pr: "12px" }} xs={12} md={6} lg={4}>
              <Typography variant="body1" sx={{ color: "white", m: 1 }}>
                <MailOutlineIcon />
                &nbsp; : &nbsp;
                <Link
                  href="mailto:info@quicksmart.ai"
                  style={{ color: "white" }}
                  target="_blank"
                  underline="always"
                >
                  info@quicksmart.ai
                </Link>
              </Typography>
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <Typography variant="body1" sx={{ color: "white", m: 1 }}>
                <CallIcon /> &nbsp;: &nbsp;
                <Link
                  href="tel:+919717782166"
                  style={{ color: "white" }}
                  target="_blank"
                  underline="always"
                >
                  +91 9717782166
                </Link>
              </Typography>
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <Typography variant="body1" sx={{ color: "white", m: 1 }}>
                Toll Free &nbsp;: &nbsp;
                <Link
                  href="tel:18008912166"
                  style={{ color: "white" }}
                  target="_blank"
                  underline="always"
                >
                  18008912166
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <img src={FooterBottom} alt="FooterBottomImage" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeroFooter;
