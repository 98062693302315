import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styles from "./index.module.scss";

const Frequently = () => {
  const faqItems = [
    {
      question: "What is QuickSmart AI?",
      answer:
        "QuickSmart AI is a pioneering company specializing in the integration of artificial intelligence with SIP VoIP telephony. We leverage over a decade of expertise to provide advanced, AI-driven communication solutions that enhance efficiency, reliability, and intelligence in business interactions.",
    },
    {
      question: "How does QuickSmart AI utilize AI in VoIP services?",
      answer:
        " We employ AI to offer intelligent VoIP services that include smart call routing, voice recognition, and real-time analytics. These features are designed to improve decision-making, enhance customer satisfaction, and provide a more personalized communication experience.",
    },
    {
      question:
        "What makes QuickSmart AI different from other VoIP service providers?",
      answer:
        " Our unique approach focuses on the integration of AI, offering customized solutions that are predictive, adaptive, and capable of transforming business communication. Our dedication to reliability and 24/7 support sets us apart, ensuring your business communications are always at their best.",
    },
    {
      question:
        "Can QuickSmart AI optimize both inbound and outbound communication processes?",
      answer:
        "Yes, leveraging AI technology, we streamline both inbound and outbound communication processes, ensuring they are handled with unprecedented efficiency and personalization.",
    },
    {
      question: "Are QuickSmart AI’s solutions customizable?",
      answer:
        " Absolutely. We recognize the unique challenges and opportunities of each business and offer tailored AI integrations that seamlessly blend with your existing VoIP infrastructure, creating a truly intelligent communication ecosystem.",
    },
    {
      question: "What kind of support can I expect from QuickSmart AI?",
      answer:
        "QuickSmart AI provides round-the-clock support to ensure your communication systems operate flawlessly. Our team is committed to reliability and excellence, offering comprehensive assistance whenever you need it.",
    },
    {
      question: "How does QuickSmart AI ensure its solutions are future-proof?",
      answer:
        "Our forward-thinking approach to integrating AI with VoIP telephony puts your business ahead, preparing you to embrace the future of communication. We continuously innovate and update our solutions to stay at the forefront of technology trends.",
    },
    {
      question: "Who can benefit from QuickSmart AI’s services?",
      answer:
        "Businesses of all sizes looking to enhance their communication strategies can benefit from our services. Whether you're aiming to improve customer interaction or streamline your sales processes, our AI-driven solutions are designed to meet diverse needs.",
    },
    {
      question: "How can I get started with QuickSmart AI?",
      answer:
        "To get started, contact us through our website or customer service hotline. We'll discuss your business needs, assess your current communication infrastructure, and propose a customized AI-driven solution tailored to your objectives.",
    },
    {
      question:
        "What is QuickSmart AI’s vision for the future of business communication?",
      answer:
        "Our vision is to revolutionize business communication through AI-driven technology, making interactions smarter, faster, and more personalized. We aim to be at the forefront of this transformation, ensuring maximum satisfaction and efficiency in every communication process.",
    },
  ];
  const [expanded, setExpanded] = React.useState(null); // Track the expanded state

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  return (
    <section className={styles.frequently_main_component}>
      <Grid className={styles.frequently_sub_component}>
        <h3>Frequently asked questions</h3>
        <Grid className={styles.accordion_box}>
          {/* <Accordion className={styles.accordion_main}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Couldn’t I build this myself and save money?
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className={styles.accordion_main}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Sounds good — but I’m building a custom X for Y...
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion className={styles.accordion_main}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Is it going to be hard to set up?
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion> */}
          {faqItems?.map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              className={styles.accordion_main}
            >
              <AccordionSummary
                expandIcon={
                  expanded === `panel${index}` ? (
                    <RemoveIcon style={{ color: "white" }} />
                  ) : (
                    <AddIcon style={{ color: "white" }} />
                  )
                }
              >
                <Typography variant="h6">{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </section>
  );
};

export default Frequently;
