import React from "react";
import { Grid } from "@mui/material";
import styles from "./index.module.scss";
import Form from "../../../components/Form";

const FormBox = () => {
  return (
    <section className={styles.main_form_container} id="trail">
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid
          className={styles.form_box_container}
          item
          xs={12}
          md={6}
          lg={6}
          xl={4}
        >
          <Form />
        </Grid>
      </Grid>
    </section>
  );
};

export default FormBox;
