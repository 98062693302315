import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import useAdmin from "../../../hooks/Admin";
import { useStyles } from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmptyPlaceholder from "../../../common/EmptyPlaceholder";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Chip } from "@mui/material";
const ColorfulChip = ({ label, colorFunc, borderRadius = "20px" }) => {
  console.log("label", label);
  const color = (label) => {
    if (label === "positive") return "#4caf50";
    else if (label === "neutral") return "#2196f3";
    else if (label === "negative") return "#f44336";
    else return "#000"; // Default color
  };
  const textColor = (label) => {
    if (label === "positive") return "white";
    else if (label === "neutral") return "white";
    else if (label === "negative") return "white";
    else return "white"; // Default color
  };
  return (
    <Box
      display={"flex"}
      sx={{ pb: 1 }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography variant="body1">Intent:&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
      <Chip
        label={label}
        sx={{
          backgroundColor: color(label),
          color: textColor(label),
          fontWeight: "bold",
          borderRadius: borderRadius,
        }}
      />
    </Box>
  );
};
const EngagementLevel = ({ engagement_level = 0 }) => {
  return (
    <Box
      display={"flex"}
      sx={{ pb: 1 }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography variant="body1">
        Engagement Level:&nbsp;&nbsp;&nbsp;&nbsp;
      </Typography>
      <Typography variant="body1">
        {engagement_level.toString().includes("%")
          ? engagement_level
          : `${engagement_level} %`}
      </Typography>
    </Box>
  );
};
const AnalyticsComponent = ({ anyaltics }) => {
  try {
    const parsedAnalytics = JSON.parse(anyaltics);
    return (
      <>
        {parsedAnalytics && <ColorfulChip label={parsedAnalytics.intent} />}
        {parsedAnalytics && (
          <EngagementLevel
            engagement_level={parsedAnalytics.engagement_level}
          />
        )}
      </>
    );
  } catch (error) {
    console.error("Error parsing analytics JSON:", error);
    return <div>{anyaltics}</div>;
  }
};

const ResultsTable = ({ results }) => {
  try {
    const parsedResults = results && JSON.parse(results);
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Answer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parsedResults &&
              parsedResults?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row?.question}</TableCell>
                  <TableCell>{row?.answer}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } catch (error) {
    console.error("Error parsing results JSON:", error);
    return <div>{results}</div>;
  }
};

const PostCallAnalysisPage = () => {
  const { id } = useParams();
  const { postCallAnalysis, getPostCallAnalysisAction } = useAdmin();
  const navigate = useNavigate();
  const classes = useStyles();
  React.useEffect(() => {
    // getChatAction("b8bac416-6677-4735-ad02-a86d2edd878a");
    getPostCallAnalysisAction(id);
  }, [id]);

  // Extract the array using regex

  const results = React?.useMemo(() => {
    if (postCallAnalysis && postCallAnalysis.data) {
      try {
        const x = postCallAnalysis.data.replace("]", "]______");
        return postCallAnalysis && JSON.parse(x).split("______")[0];
      } catch (error) {
        return null;
      }
    }
  }, [postCallAnalysis]);
  const anyaltics = React?.useMemo(() => {
    try {
      if (postCallAnalysis && postCallAnalysis.data) {
        const x = postCallAnalysis.data.replace("]", "]______");
        return postCallAnalysis && JSON.parse(x).split("______")[1];
      }
    } catch (error) {
      return null;
    }
  }, [postCallAnalysis]);

  console.log(postCallAnalysis, "postCallAnalysis");
  return (
    <Box p={2}>
      <IconButton onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>
      <Box
        sx={
          {
            // height: "83vh",
            // marginBottom: "15px",
          }
        }
        className={classes.transcript_conatiner}
      >
        {postCallAnalysis ? (
          <>
            <Box display="flex" sx={{ px: 1, py: 1 }}>
              <Card
                sx={{
                  width: "100%",
                  bgcolor: "grey.0",
                  boxShadow: "none",
                  ml: 1,
                }}
              >
                <CardContent
                  sx={{
                    p: 1,
                    pb: "8px !important",
                    width: "fit-content",
                    mx: "auto",
                    border: `1px solid`,
                    bgcolor: "grey.100",
                    borderColor: "grey.200",
                  }}
                >
                  <Box
                    display={"flex"}
                    sx={{ pb: 1 }}
                    justifyContent={"space-around"}
                    alignItems={"center"}
                  >
                    {anyaltics && <AnalyticsComponent anyaltics={anyaltics} />}
                  </Box>
                  <ResultsTable results={results} />
                  {postCallAnalysis &&
                    postCallAnalysis.data &&
                    (anyaltics === null || results === null) && (
                      <Typography
                        variant="subtitle1"
                        sx={{
                          overflowWrap: "anywhere",
                          display: "inline-block",
                        }}
                      >
                        {JSON.parse(postCallAnalysis.data)}
                      </Typography>
                    )}

                  {/* {results.map((_rl) => {
                                        return <Typography
                                            variant="subtitle1"
                                            sx={{
                                                overflowWrap: "anywhere",
                                                display: "inline-block",
                                            }}
                                        >
                                            {results}
                                        </Typography>
                                    })} */}
                  {/* <WordList wrapper={wrapper} currentTime={currentTime} words={history.comment_text} /> */}
                </CardContent>
              </Card>
            </Box>
          </>
        ) : (
          <Grid className={classes.transcript_conatiner}>
            <EmptyPlaceholder msg="No data" height={468} />
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default PostCallAnalysisPage;
