import React from "react";
// Layout
import Drawer from "../layout";

// Pages
import LoginTab from "../components/Tabs";
import AiAgent from "../pages/AiAgent";
import Phone from "../pages/Phone";
import Billing from "../pages/Billing";
import Apikeys from "../pages/Apikeys";
import Webhook from "../pages/Webhook";
import AdminDrawer from "../Muilayout";
import Call from "../pages/Admin/call";
import AdminChat from "../pages/Admin/call/chat";
import AdminPostCallAnalysis from "../pages/Admin/call/postCallAnalysis";
import Cases from "../pages/Admin/Cases";
import Country from "../pages/Admin/Country";
import Dids from "../pages/Admin/Dds";
import Chat from "../pages/call/chat";
import { Navigate } from "react-router-dom";
import UseAuth from "../hooks/Auth";
import About from "../pages/About";
import Faq from "../pages/Faq";
import Customer from "../pages/Admin/Customer";
import Campaign from "../pages/Admin/Campaign";
import CallAction from "../pages/Admin/CallAction";

import StartConversation from "../pages/start-conversation";
import HeroScreen from "../pages/HeroScreen";
import TryOut from "../pages/TryOut";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { token, userRoles } = UseAuth();

  const isAuthorized = () => {
    if (!token) return false;
    if (!allowedRoles || allowedRoles.length === 0) return true;
    return userRoles.some((role) => allowedRoles.includes(role));
  };

  return isAuthorized() ? <>{children}</> : <Navigate to="/login" />;
};
const routes = [
  // {
  //   path: "/",
  //   element: <Home />,
  // },
  {
    path: "/",
    element: <HeroScreen />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "call/:id",
    element: <Chat />,
  },
  {
    path: "/login",
    element: <LoginTab />,
  },
  {
    path: "/demo",
    element: <StartConversation />,
  },
  {
    path: "/tryout",
    element: <TryOut />,
  },
  {
    path: "/",
    element: <Drawer />,
    children: [
      { path: "aiagent", element: <AiAgent /> },
      { path: "phone", element: <Phone /> },
      { path: "billing", element: <Billing /> },
      { path: "apikeys", element: <Apikeys /> },
      { path: "webhook", element: <Webhook /> },
    ],
  },
  {
    path: "/admin",
    element: (
      <ProtectedRoute>
        <AdminDrawer />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "call",
        element: (
          <ProtectedRoute>
            <Call />
          </ProtectedRoute>
        ),
      },
      {
        path: "call/:id",
        element: (
          <ProtectedRoute>
            <AdminChat />
          </ProtectedRoute>
        ),
      }, {
        path: "call/post/:id",
        element: (
          <ProtectedRoute>
            <AdminPostCallAnalysis />
          </ProtectedRoute>
        ),
      },
      {
        path: "cases",
        element: (
          <ProtectedRoute>
            <Cases />
          </ProtectedRoute>
        ),
      },
      {
        path: "country",
        element: (
          <ProtectedRoute>
            <Country />
          </ProtectedRoute>
        ),
      },
      {
        path: "dds",
        element: (
          <ProtectedRoute>
            <Dids />
          </ProtectedRoute>
        ),
      },
      {
        path: "customer",
        element: (
          <ProtectedRoute>
            <Customer />
          </ProtectedRoute>
        ),
      },
      {
        path: "campaign",
        element: (
          <ProtectedRoute>
            <Campaign />
          </ProtectedRoute>
        ),
      },
      {
        path: "callaction",
        element: (
          <ProtectedRoute>
            <CallAction />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export default routes;
