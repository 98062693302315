import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../../store/auth/index";

function UseAuth() {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const userRoles = useSelector((state) => state.auth.role);
  const loginAction = (params) => {
    dispatch(login(params));
  };
  const LogoutAction = () => {
    dispatch(logout());
  };
  return {
    token,
    isAuth,
    userRoles,
    loginAction,
    LogoutAction,
  };
}

export default UseAuth;
